
















































import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { HomeCancer } from "#/model/receipt";
import AxiosMixin from "@/mixins/axiosMixin";
import { DISPLAY_RECEIPT_REGIST, DISPLAY_SERVICE_CONTENT } from "../types";

@Component
export default class extends Mixins(AxiosMixin) {
  /** 在宅がん医療総合診療料ダイアログ表示フラグ */
  @Prop() private readonly value!: boolean;

  /** 操作モード 0:レセプト登録 1:サービス内容 */
  @Prop({ default: "" }) display_option!: number;

  /** 利用者ID */
  @Prop({ default: 0 }) patientId!: number;

  /** 対象月 */
  @Prop({ default: "" }) yearmonth!: string;

  /** 在宅がん医療総合診療料 */
  @Prop({ default: () => ({}) }) homeCancer!: HomeCancer;

  /** 在宅がん医療総合診療料ダイアログ表示フラグ変更時 */
  @Emit() input(value: boolean): boolean {
    return value;
  }

  /** データ更新 */
  @Emit() update() {
    return;
  }

  /** レセプト登録画面 */
  private DISPLAY_RECEIPT_REGIST = DISPLAY_RECEIPT_REGIST;

  /** サービス内容画面 */
  private DISPLAY_SERVICE_CONTENT = DISPLAY_SERVICE_CONTENT;

  /** 在宅がん医療総合診療料ダイアログ表示フラグ */
  private get Value(): boolean {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  /** 在宅がん医療総合診療料を保存する */
  private saveHomeCancer() {
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/homecancer/save",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth,
        home_cancer: this.homeCancer
      },
      () => {
        this.update();
      }
    );
  }
}
