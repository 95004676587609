


































































import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { Multiple, HighexpenseMultiple } from "#/model/receipt";
import AxiosMixin from "@/mixins/axiosMixin";
import { DISPLAY_RECEIPT_REGIST, DISPLAY_SERVICE_CONTENT } from "../types";

@Component
export default class extends Mixins(AxiosMixin) {
  /** 在宅がん医療総合診療料ダイアログ表示フラグ */
  @Prop() private readonly value!: boolean;

  /** 操作モード 0:レセプト登録 1:サービス内容 */
  @Prop({ default: "" }) display_option!: number;

  /** 利用者ID */
  @Prop({ default: 0 }) patientId!: number;

  /** 対象月 */
  @Prop({ default: "" }) yearmonth!: string;

  /** 在宅がん医療総合診療料 */
  @Prop({ default: () => ({}) }) multiple!: Multiple;

  /** 在宅がん医療総合診療料ダイアログ表示フラグ変更時 */
  @Emit() input(value: boolean): boolean {
    return value;
  }

  /** データ更新 */
  @Emit() update() {
    return;
  }

  /** レセプト登録画面 */
  private DISPLAY_RECEIPT_REGIST = DISPLAY_RECEIPT_REGIST;

  /** サービス内容画面 */
  private DISPLAY_SERVICE_CONTENT = DISPLAY_SERVICE_CONTENT;

  /** 在宅がん医療総合診療料ダイアログ表示フラグ */
  private get Value(): boolean {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  private async inputMultiple(item: HighexpenseMultiple, value: number) {
    console.log("aa");
    if (
      !(await this.$openConfirm(
        "手動で多数回該当の設定を変更した場合、選択した利用者の今月のレセプト計算において、保険等の修正を行なっても、自動で多数回該当は設定されなくなります。よろしいでしょうか？"
      ))
    ) {
      if (value == 1) {
        item.calc_multiple_rate = 0;
      } else {
        item.calc_multiple_rate = 1;
      }
      return;
    }
    item.is_set_calc_multiple_rate = 1;
  }

  /** 高額療養費を保存する */
  private saveMultiple() {
    const high_expense_multiples = [];
    for (const multiple of this.multiple.use_multiples) {
      high_expense_multiples.push(multiple.high_expense_multiple);
    }
    for (const over of this.multiple.overs) {
      high_expense_multiples.push(...over.high_expense_multiples);
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/highexpenseMultiple/save",
      {
        patient_id: this.patientId,
        high_expense_multiples: high_expense_multiples
      },
      () => {
        this.update();
        this.Value = false;
      }
    );
  }
}
