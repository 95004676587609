
















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Link } from "#/types";

@Component
export default class SideBar extends Vue {
  private links: Link[] = [];

  @Emit("clickItem")
  clickItem(index: number, link: Link) {
    return { index: index, link: link };
  }

  private get Links(): Link[] {
    return this.links;
  }

  private getBarText(link: Link) {
    if (link.mini_text) {
      return link.mini_text;
    }
    return link.text;
  }

  public updateLinks(arr: Link[]) {
    this.links.splice(0);
    arr.forEach(l => {
      this.links.push(l);
    });
  }
}
