































































































































































































import { Component } from "vue-property-decorator";
import EveryTimeV2 from "./EveryTimeV2.vue";

@Component
export default class EveryTimeV3 extends EveryTimeV2 {
  created(): void {
    this.setTimesDiv();
    this.setKinouKyokaDivV2();
    this.setMentalSupportDiv();
  }

  //複数回訪問:回数
  protected disableCheckTimesDivV3(): boolean {
    let result = false;
    if (
      (this.event.visit_record.mental_div == 2 &&
        this.officeHistory.medical_seishin_fukusukai_add == 0) ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //複数回訪問:同一建物内
  protected disableCheckTimesThreepersonDiv(): boolean {
    let result = false;
    if (
      (this.event.visit_record.mental_div == 1 &&
        this.receiptVisitRecord.remedy_div != 2) ||
      (this.event.visit_record.mental_div == 2 &&
        this.receiptVisitRecord.remedy_div != 3) ||
      (this.event.visit_record.mental_div == 2 &&
        this.officeHistory.medical_seishin_fukusukai_add == 0) ||
      this.baseDisableCheck()
    ) {
      result = true;
      this.receiptVisitRecord.times_threeperson_div = 1;
    }
    return result;
  }

  //複数名訪問:同一建物内
  protected disableCheckAccompaniedStaffThreepersonDiv(): boolean {
    let result = false;
    if (
      (this.event.visit_record.mental_div == 1 &&
        this.receiptVisitRecord.remedy_div != 2) ||
      (this.event.visit_record.mental_div == 2 &&
        this.receiptVisitRecord.remedy_div != 3) ||
      this.baseDisableCheck()
    ) {
      result = true;
      this.receiptVisitRecord.accompanied_staff_threeperson_div = 1;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //提供時間
    if (this.disableCheckMedicalTimeZoneDiv()) {
      this.receiptVisitRecord.medical_time_zone_div = 0;
    }
    //複数回訪問:回数
    if (this.disableCheckTimesDivV3()) {
      this.times_div2 = false;
      this.times_div3 = false;
      this.receiptVisitRecord.times_div = 0;
    }
    //複数回訪問:同一建物内（保存時のみ0にする特殊ケース）
    if (this.disableCheckTimesThreepersonDiv()) {
      this.receiptVisitRecord.times_threeperson_div = 0;
    }
    //複数名訪問:職員種別
    if (this.disableCheckAccompaniedStaffDiv()) {
      this.receiptVisitRecord.accompanied_staff_div = 0;
    }
    //複数名訪問:（ニ）（ハ）
    if (this.disableCheckAccompaniedHojoStaffDiv()) {
      this.receiptVisitRecord.accompanied_hojo_staff_div = 0;
    }
    //複数名訪問:回数区分
    if (this.disableCheckAccompaniedStaffCnt()) {
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt2 = false;
      this.accompanied_staff_cnt3 = false;
      this.receiptVisitRecord.accompanied_staff_cnt = 0;
    }
    //複数名訪問:同一建物内（保存時のみ0にする特殊ケース）
    if (this.disableCheckAccompaniedStaffThreepersonDiv()) {
      this.receiptVisitRecord.accompanied_staff_threeperson_div = 0;
    }
    //機能強化型訪問看護管理療養費1
    if (
      this.disableCheckKinouKyokaDiv1() &&
      !this.kinou_kyoka_div2 &&
      !this.kinou_kyoka_div3
    ) {
      this.kinou_kyoka_div1 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費2
    if (
      this.disableCheckKinouKyokaDiv2() &&
      !this.kinou_kyoka_div1 &&
      !this.kinou_kyoka_div3
    ) {
      this.kinou_kyoka_div2 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費3
    if (
      this.disableCheckKinouKyokaDiv3() &&
      !this.kinou_kyoka_div1 &&
      !this.kinou_kyoka_div2
    ) {
      this.kinou_kyoka_div3 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //退院時共同指導
    if (this.disableCheckMedicalLeaveInstFlg()) {
      this.receiptVisitRecord.medical_leave_inst_flg = 0;
    }
    //退院支援指導
    if (this.disableCheckLeaveSupportFlg()) {
      this.receiptVisitRecord.leave_support_flg = 0;
    }
    //退院日
    if (this.disableCheckLeaveSupportDate()) {
      this.receiptVisitRecord.leave_support_date = "";
    }
  }
}
