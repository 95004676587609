import { ActionTree } from "vuex";
import {
  SAVE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  FirestoreState,
  GET_DOCUMENT,
  SET_DOCUMENT_DATA,
  SET_DOCUMENT_ID
} from "./types";
import { RootState } from "@/store/types";
import { firebase } from "@/firebase";

export const actions: ActionTree<FirestoreState, RootState> = {
  /** Firestore保存 呼び出し側でエラー制御 */
  [SAVE_DOCUMENT]: async (
    { state, commit },
    document: firebase.firestore.DocumentData
  ) => {
    if (!state.documentId || state.documentId == "0") {
      return;
    }
    await state.firebaseData?.set(document);
    commit(SET_DOCUMENT_DATA, document);
  },
  /** Firestore更新 呼び出し側でエラー制御 */
  [UPDATE_DOCUMENT]: async (
    { state, commit },
    document: firebase.firestore.DocumentData
  ) => {
    if (!state.documentId || state.documentId == "0") {
      return;
    }
    await state.firebaseData?.update(document);
    commit(SET_DOCUMENT_DATA, document);
  },
  /** Firestore削除 呼び出し側でエラー制御 */
  [DELETE_DOCUMENT]: async ({ state, commit }) => {
    if (!state.documentId || state.documentId == "0") {
      return;
    }
    //ドキュメントが削除される前にオブザーバーを停止する
    if (state.unsubscribe != undefined) {
      state.unsubscribe();
    }
    await state.firebaseData?.delete();
    commit(SET_DOCUMENT_DATA, undefined);
  },
  /** Firestore取得 呼び出し側でエラー制御 */
  [GET_DOCUMENT]: async ({ state, commit }, documentId: string) => {
    if (!documentId || documentId == "0") {
      return;
    }
    commit(SET_DOCUMENT_ID, documentId);
    const snapshot = await state.firebaseData?.get();
    commit(SET_DOCUMENT_DATA, snapshot?.data());
  }
};
