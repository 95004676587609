










































































































































































import { Component, Watch } from "vue-property-decorator";
import EveryTime from "./EveryTime.vue";

@Component
export default class EveryTimeV2 extends EveryTime {
  created(): void {
    this.setTimesDiv();
    this.setKinouKyokaDivV2();
    this.setMentalSupportDiv();
  }

  //機能強化区分
  protected setKinouKyokaDivV2() {
    if (this.receiptVisitRecord.kinou_kyoka_div == 1) {
      this.kinou_kyoka_div1 = true;
    }
    if (this.receiptVisitRecord.kinou_kyoka_div == 2) {
      this.kinou_kyoka_div2 = true;
    }
    if (this.receiptVisitRecord.kinou_kyoka_div == 3) {
      this.kinou_kyoka_div3 = true;
    }
  }

  //複数名訪問回数区分
  protected setMentalSupportDiv() {
    if (this.receiptVisitRecord.accompanied_staff_cnt == 1) {
      this.accompanied_staff_cnt1 = true;
      this.accompanied_staff_cnt2 = false;
      this.accompanied_staff_cnt3 = false;
    }
    if (this.receiptVisitRecord.accompanied_staff_cnt == 2) {
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt2 = true;
      this.accompanied_staff_cnt3 = false;
    }
    if (this.receiptVisitRecord.accompanied_staff_cnt == 3) {
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt2 = false;
      this.accompanied_staff_cnt3 = true;
    }
  }

  /** 機能強化区分3 */
  protected kinou_kyoka_div3 = false;

  /** 複数名訪問回数区分1回 */
  protected accompanied_staff_cnt1 = false;

  /** 複数名訪問回数区分2回 */
  protected accompanied_staff_cnt2 = false;

  /** 複数名訪問回数区分3回以上 */
  protected accompanied_staff_cnt3 = false;

  //複数名職員種別（看護補助以外で複数名看護補助区分空にする）
  @Watch("receiptVisitRecord.accompanied_staff_div")
  protected handleAccompaniedStaffDiv() {
    if (!this.isLoaded) {
      return;
    }
    if (this.receiptVisitRecord.accompanied_staff_div != 7) {
      this.receiptVisitRecord.accompanied_hojo_staff_div = 0;
    }
  }

  //複数名訪問回数区分1回
  @Watch("accompanied_staff_cnt1") protected handleAccompaniedStaffCnt1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.accompanied_staff_cnt1 == true) {
      this.receiptVisitRecord.accompanied_staff_cnt = 1;
      this.accompanied_staff_cnt2 = false;
      this.accompanied_staff_cnt3 = false;
    }
    if (
      this.accompanied_staff_cnt1 == false &&
      this.accompanied_staff_cnt2 == false &&
      this.accompanied_staff_cnt3 == false
    ) {
      this.receiptVisitRecord.accompanied_staff_cnt = 0;
    }
  }

  //複数名訪問回数区分2回
  @Watch("accompanied_staff_cnt2") protected handleAccompaniedStaffCnt2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.accompanied_staff_cnt2 == true) {
      this.receiptVisitRecord.accompanied_staff_cnt = 2;
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt3 = false;
    }
    if (
      this.accompanied_staff_cnt1 == false &&
      this.accompanied_staff_cnt2 == false &&
      this.accompanied_staff_cnt3 == false
    ) {
      this.receiptVisitRecord.accompanied_staff_cnt = 0;
    }
  }

  //複数名訪問回数区分3回以上
  @Watch("accompanied_staff_cnt3") protected handleAccompaniedStaffCnt3() {
    if (!this.isLoaded) {
      return;
    }
    if (this.accompanied_staff_cnt3 == true) {
      this.receiptVisitRecord.accompanied_staff_cnt = 3;
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt2 = false;
    }
    if (
      this.accompanied_staff_cnt1 == false &&
      this.accompanied_staff_cnt2 == false &&
      this.accompanied_staff_cnt3 == false
    ) {
      this.receiptVisitRecord.accompanied_staff_cnt = 0;
    }
  }

  //機能強化区分3
  @Watch("kinou_kyoka_div3") protected handleKinouKyokaDiv3() {
    if (!this.isLoaded) {
      return;
    }
    if (this.kinou_kyoka_div3 == true) {
      this.receiptVisitRecord.kinou_kyoka_div = 3;
    }
    if (this.kinou_kyoka_div3 == false) {
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
  }

  //複数名訪問:（ニ）（ハ）
  protected disableCheckAccompaniedHojoStaffDiv(): boolean {
    let result = false;
    if (
      this.event.visit_record.mental_div == 2 ||
      this.receiptVisitRecord.accompanied_staff_div != 7 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //複数名訪問:回数区分
  protected disableCheckAccompaniedStaffCnt(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.accompanied_staff_div == 0 ||
      (this.event.visit_record.mental_div == 1 &&
        (this.receiptVisitRecord.accompanied_staff_div != 7 ||
          this.receiptVisitRecord.accompanied_hojo_staff_div != 2)) ||
      (this.event.visit_record.mental_div == 2 &&
        (this.receiptVisitRecord.accompanied_staff_div == 4 ||
          this.receiptVisitRecord.accompanied_staff_div == 6 ||
          this.receiptVisitRecord.accompanied_staff_div == 7 ||
          this.receiptVisitRecord.accompanied_staff_div == 9)) ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //機能強化型訪問看護管理療養費3
  protected disableCheckKinouKyokaDiv3(): boolean {
    let result = false;
    if (this.officeHistory.medical_kinou_kyoka_ryouyouhi3 == 0) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV2() {
    //提供時間
    if (this.disableCheckMedicalTimeZoneDiv()) {
      this.receiptVisitRecord.medical_time_zone_div = 0;
    }
    //複数回訪問:回数
    if (this.disableCheckTimesDiv()) {
      this.times_div2 = false;
      this.times_div3 = false;
      this.receiptVisitRecord.times_div = 0;
    }
    //複数名訪問:職員種別
    if (this.disableCheckAccompaniedStaffDiv()) {
      this.receiptVisitRecord.accompanied_staff_div = 0;
    }
    //複数名訪問:（ニ）（ハ）
    if (this.disableCheckAccompaniedHojoStaffDiv()) {
      this.receiptVisitRecord.accompanied_hojo_staff_div = 0;
    }
    //複数名訪問:回数区分
    if (this.disableCheckAccompaniedStaffCnt()) {
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt2 = false;
      this.accompanied_staff_cnt3 = false;
      this.receiptVisitRecord.accompanied_staff_cnt = 0;
    }
    //機能強化型訪問看護管理療養費1
    if (
      this.disableCheckKinouKyokaDiv1() &&
      !this.kinou_kyoka_div2 &&
      !this.kinou_kyoka_div3
    ) {
      this.kinou_kyoka_div1 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費2
    if (
      this.disableCheckKinouKyokaDiv2() &&
      !this.kinou_kyoka_div1 &&
      !this.kinou_kyoka_div3
    ) {
      this.kinou_kyoka_div2 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費3
    if (
      this.disableCheckKinouKyokaDiv3() &&
      !this.kinou_kyoka_div1 &&
      !this.kinou_kyoka_div2
    ) {
      this.kinou_kyoka_div3 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //退院時共同指導
    if (this.disableCheckMedicalLeaveInstFlg()) {
      this.receiptVisitRecord.medical_leave_inst_flg = 0;
    }
    //退院支援指導
    if (this.disableCheckLeaveSupportFlg()) {
      this.receiptVisitRecord.leave_support_flg = 0;
    }
    //退院日
    if (this.disableCheckLeaveSupportDate()) {
      this.receiptVisitRecord.leave_support_date = "";
    }
  }
}
