import Vue from "vue";
import Component from "vue-class-component";
import { firebase, db } from "@/firebase";
import { networkErrorMessage } from "./const";

type QueryFunc = (
  query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
) => firebase.firestore.Query<firebase.firestore.DocumentData>;
type OnChangeFunc<T> = (
  docChange: firebase.firestore.DocumentChange<T>
) => void;
type ListenerDetachFunc = () => void;

@Component
export default class FireStoreMixin extends Vue {
  public collection = ""; // コレクション名
  public documentId = ""; // ドキュメントID

  // インスタンスのみ取得
  public async getDocInstance(
    collection: string,
    documentId: string
  ): Promise<firebase.firestore.DocumentReference | boolean> {
    try {
      return await db.collection(collection).doc(documentId);
    } catch {
      await this.$openAlert(networkErrorMessage);
      return false;
    }
  }

  // インスタンスから取得
  public async documentGetByIns(
    instance: firebase.firestore.DocumentReference
  ): Promise<firebase.firestore.DocumentData | undefined | boolean> {
    try {
      const snapshot = await instance.get();
      if (!snapshot.exists) {
        return undefined;
      }
      return snapshot.data();
    } catch {
      await this.$openAlert(networkErrorMessage);
      return false;
    }
  }

  // インスタンスで保存
  public async documentSaveByIns(
    instance: firebase.firestore.DocumentReference,
    document: firebase.firestore.DocumentData,
    func?: () => void
  ): Promise<boolean> {
    const res = await instance
      .set(document)
      .then(() => {
        if (func) {
          func();
        }
        return true;
      })
      .catch(async () => {
        await this.$openAlert(networkErrorMessage);
        await this.$openAlert(
          "画面情報を正しく保存できませんでした。画面を更新して再度お試しください。"
        );
        return false;
      });
    return res;
  }

  // インスタンス生成〜取得まで
  public async getInstanceDocument(
    collection: string,
    documentId: string
  ): Promise<
    | false
    | {
        instance: firebase.firestore.DocumentReference;
        data: firebase.firestore.DocumentData | undefined;
      }
  > {
    let instance: firebase.firestore.DocumentReference | boolean = false;
    let data: firebase.firestore.DocumentData | undefined | boolean = false;
    // インスタンス
    await this.retry(
      async () => {
        instance = await this.getDocInstance(collection, documentId);
      },
      () => {
        return instance != false;
      }
    );

    // データ
    if (typeof instance !== "boolean") {
      await this.retry(
        async () => {
          if (typeof instance !== "boolean") {
            data = await this.documentGetByIns(instance);
          }
        },
        () => {
          return data != false;
        }
      );
    } else {
      // インスタンス取得知失敗
      return false;
    }
    if (data === false) {
      // データ取得失敗
      return false;
    }
    return { instance: instance, data: data };
  }

  public async documentGet(): Promise<
    firebase.firestore.DocumentData | undefined | boolean
  > {
    try {
      const snapshot = await db
        .collection(this.collection)
        .doc(this.documentId)
        .get();
      if (!snapshot.exists) {
        return undefined;
      }
      return snapshot.data();
    } catch {
      await this.$openAlert(networkErrorMessage);
      return false;
    }
  }

  public getDocument() {
    return db.collection(this.collection).doc(this.documentId);
  }

  /**
   * FireStoreにdocumentを保存する
   *
   * 成功/失敗で処理を分ける必要が生じたら対応
   */
  public async documentSave(
    document: firebase.firestore.DocumentData,
    func?: () => void
  ): Promise<boolean> {
    const res = await db
      .collection(this.collection)
      .doc(this.documentId)
      .set(document)
      .then(() => {
        if (func) {
          func();
        }
        return true;
      })
      .catch(async () => {
        await this.$openAlert(networkErrorMessage);
        await this.$openAlert(
          "画面情報を正しく保存できませんでした。画面を更新して再度お試しください。"
        );
        return false;
      });
    return res;
  }

  // リトライ処理
  public async retry(func: Function, isBreakFunc: Function) {
    for (let i = 0; i < 3; i++) {
      await func();
      if (isBreakFunc() || i === 2) {
        break;
      }
      await new Promise(r => setTimeout(r, 1000));
    }
  }

  /**
   * FireStoreからdocumentを削除する
   *
   * 成功/失敗で処理を分ける必要が生じたら対応
   */
  public documentDelete(func?: () => void): void {
    db.collection(this.collection)
      .doc(this.documentId)
      .delete()
      .then(() => {
        if (func) {
          func();
        }
      })
      .catch(async () => {
        await this.$openAlert(networkErrorMessage);
      });
  }

  /** 指定コレクション(ドキュメント)に対するリアルタイムアップデート用のリスナーを取得 */
  public getListner<T>(
    queryFunc: QueryFunc,
    onChangeFunc: OnChangeFunc<T>
  ): ListenerDetachFunc {
    const detach = queryFunc(db.collection(this.collection))
      .withConverter(this.converter<T>())
      .onSnapshot(ss => {
        ss.docChanges().forEach(docChange => {
          onChangeFunc(docChange);
        });
      });

    return detach;
  }

  private converter = function<T>(): firebase.firestore.FirestoreDataConverter<
    T
  > {
    return {
      toFirestore: (data: T) => {
        return data;
      },
      fromFirestore: (
        snapshot: firebase.firestore.QueryDocumentSnapshot<T>,
        options: firebase.firestore.SnapshotOptions
      ) => {
        const data = snapshot.data(options);
        return data;
      }
    };
  };

  /** ドキュメント新規追加(ID自動付与) */
  public async documentAdd(
    data: firebase.firestore.DocumentData,
    successFunc: (
      ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    ) => void,
    errorFunc: () => void
  ) {
    const res = await db
      .collection(this.collection)
      .add(data)
      .then(ref => {
        successFunc(ref);
        return true;
      })
      .catch(() => {
        errorFunc();
        return false;
      });
    return res;
  }

  /**
   * FireStoreのdocumentを更新する
   */
  public async documentUpdate(
    updateData: firebase.firestore.UpdateData,
    func?: () => void
  ): Promise<boolean> {
    const res = await db
      .collection(this.collection)
      .doc(this.documentId)
      .update(updateData)
      .then(() => {
        if (func) {
          func();
        }
        return true;
      })
      .catch(async () => {
        await this.$openAlert(networkErrorMessage);
        await this.$openAlert(
          "画面情報を正しく保存できませんでした。画面を更新して再度お試しください。"
        );
        return false;
      });
    return res;
  }
}
