





















































import { Component } from "vue-property-decorator";
import RegularPartBase from "./RegularPartBase";
import {
  ExceptForRegularTourDay,
  DefaultExceptForRegularTourDays
} from "#/model/receipt";

@Component
export default class ExceptForRegularTourDays extends RegularPartBase {
  //定期巡回除外日追加
  protected add() {
    const exceptForRegularTourDay: ExceptForRegularTourDay = DefaultExceptForRegularTourDays();
    this.exceptForRegularTourDays.push(exceptForRegularTourDay);
  }

  //定期巡回除外日:日付チェック（from）
  protected chackExceptForRegularTourDayStart(
    start: string,
    end: string
  ): boolean | string {
    //開始・終了ともに未入力の場合はスキップ
    if (end == "" && start == "") {
      return true;
    }
    //終了あるのに開始未入力
    if (end != "" && start == "") {
      return "必須です";
    }
    //開始日が終了日より後
    if (end != "" && Number(start) > Number(end)) {
      return "開始日が終了日より後になっています";
    }
    //当月範囲外
    if (Number(start) > Number(this.endDay) || Number(start) < 1) {
      return "入力が正しくありません";
    }
    return true;
  }

  //定期巡回除外日:日付チェック（to）
  protected chackExceptForRegularTourDayEnd(
    start: string,
    end: string
  ): boolean | string {
    //開始・終了ともに未入力の場合はスキップ
    if (start == "" && end == "") {
      return true;
    }
    //開始あるのに終了未入力
    if (start != "" && end == "") {
      return "必須です";
    }
    //終了日が開始日より前
    if (start != "" && Number(start) > Number(end)) {
      return "終了日が開始日より前になっています";
    }
    //当月範囲外
    if (Number(end) > Number(this.endDay) || Number(end) < 1) {
      return "入力が正しくありません";
    }
    return true;
  }

  //定期巡回除外日:日付重複チェック
  protected chackExceptForRegularTourDayDuplication(
    start: string,
    end: string
  ): boolean | string {
    //期間かぶりチェック
    if (start != "" && end != "") {
      let counter = 0;
      for (const day of this.exceptForRegularTourDays) {
        if (
          Number(start) <= Number(day.end) &&
          Number(end) >= Number(day.start)
        ) {
          counter += 1;
        }
        if (counter == 2) {
          return "重複しています。";
        }
      }
    }
    return true;
  }
}
