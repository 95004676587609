






































































































































































































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import SexSelect from "@/components/common_ibow/SexSelect.vue";
import YesNoSelect from "@/components/common_ibow/YesNoSelect.vue";
import JobTypeSelect from "@/components/common_ibow/JobTypeSelect.vue";
import * as constant from "#/const";
import { Patient } from "#/model/patient";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import DateInput from "#/components/DateInput.vue";

@Component({
  components: {
    AppPlaces,
    SexSelect,
    YesNoSelect,
    JobTypeSelect,
    DateInput
  }
})

/** 利用者基礎情報 基本情報フォーム */
export default class FormBaseInfo extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 利用者情報(編集対象) */
  @Prop() patient!: Patient;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** プレフィックスID(入力項目にIDを振る対応) */
  @Prop({ default: "patient-info" }) private prefixId!: string;

  /* 定数 */

  /** 訪問場所 */
  private VISIT_PLACE_DIV_FACILITY = constant.PATIENT.VISIT_PLACE_DIV_FACILITY;
}
