





































































































































































































import {
  Component,
  Mixins,
  Ref,
  Prop,
  Emit,
  Watch
} from "vue-property-decorator";
import { Choice, SortTypeSelect } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import SearchSelect from "#/components/SearchSelect.vue";
import {
  BoardItem,
  DefaultBoardItem,
  SearchCondition,
  DefaultSearchCondition
} from "#/model/fulltime";
import { VForm } from "@/types";
import { StatusList } from "@/components/patientsearch/search";
import Hour24Item from "@/components/hour24/hour24Item.vue";
import OfficeSelects from "@/components/common_ibow/OfficeSelects.vue";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { COLLECTION_SEARCH_COND_FULLTIME } from "@/const/envFireStore";
import InfiniteLoading from "vue-infinite-loading";

export const DefaultSearchMode = "1";

@Component({
  components: {
    SearchSelect,
    OfficeSelects,
    Hour24Item,
    InfiniteLoading
  }
})
export default class Hour24Board extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  FireStoreMixin
) {
  /** 無限ローディング */
  @Ref("infiniteLoading") private readonly infiniteLoading?: InfiniteLoading;

  /** フォームエリア */
  @Ref("form") private readonly form!: VForm;

  /** 利用者リスト操作用 */
  @Ref("patientList") private readonly patientSelect!: SearchSelect;

  /** 利用者ID */
  @Prop({ default: 0 }) private readonly patientId!: number; //利用者24時間体制から来る利用者Id

  /** 検索する24時間体制の状態 1:全て 2:未読 3:既読 4:お気に入り */
  @Prop({ default: DefaultSearchMode }) private readonly searchMode!: string;

  /** 検索欄を非表示にするか */
  @Prop({ default: false }) private readonly noSearcForm!: boolean;

  /** 詳細エリアをドロワーとして表示するか */
  @Prop({ default: true }) private isOverlayDetail!: boolean;

  @Emit("updateStartEnd")
  updateStartEnd(newValue: unknown) {
    return newValue;
  }

  /** 検索条件 */
  private searchCond: SearchCondition = DefaultSearchCondition();

  /** 前回検索分の選択利用者ID */
  private beforePatientIds: number[] = [];

  /** グループ一覧 */
  private groups: Choice[] = [];

  /** 入力コメント内容 */
  private comment = ""; //入力コメント

  /** 職員ID */
  private staffId = 0;

  /** 24時間体制アイテム一覧 */
  private fulltimeItems: BoardItem[] = []; //未読一覧

  /** 選択した24時間体制アイテム */
  private readingItem: BoardItem = DefaultBoardItem();

  /** 24時間体制登録利用者リスト */
  private patientList: Choice[] = [];

  /** 一回で取得するデータ量 */
  private limit = 20; //一回で取得するデータ量

  /** 読み込みページ数 */
  private page = 0;

  /** 開いている24時間体制詳細の項目ID */
  private openingItemId = 0;

  /** 開いている/直前まで開いていた24時間体制項目のID */
  private lastOpenItemId = 0;

  /** 検索パネルの開閉状態 */
  private searchPanel = false;
  private get SearchPanel() {
    return this.searchPanel;
  }
  private set SearchPanel(isOpen: boolean) {
    this.searchPanel = isOpen;
    if (isOpen && this.openingItemId !== 0) {
      // 絞り込む時詳細エリアは閉じる
      this.closeDetail(this.openingItemId);
      this.openingItemId = 0;
      this.realignDetailSpace();
    }
  }

  /** 詳細エリアの幅 */
  private detailPanelWidth = "500px";

  /** リサイズ前の描画幅 */
  private beforeRootWidth = document.documentElement.clientWidth;

  /** 利用者状況リスト */
  private statusList = StatusList;

  /** ソート方法リスト  */
  private sortRuleList: SortTypeSelect[] = [
    { text: "対応日時 - 新しい順", sort: 0, sortType: 0, select: "対応日時順" },
    { text: "対応日時 - 古い順", sort: 0, sortType: 1, select: "対応日時順" },
    { text: "更新日 - 新しい順", sort: 1, sortType: 0, select: "更新日順" },
    { text: "更新日 - 古い順", sort: 1, sortType: 1, select: "更新日順" }
  ];

  /** 選択したソート方法 */
  private sortBy = this.sortRuleList[0];

  /** グループ検索条件のAND/ORとチェックボックスを合わせる */
  private get searchGroupOperator(): boolean {
    return !this.searchCond.group_operator;
  }
  private set searchGroupOperator(newCond: boolean) {
    this.searchCond.group_operator = Number(!newCond);
  }

  /** 一括既読ボタンを表示するかどうか */
  private get IsShowBatchReadButton(): boolean {
    // すべて or 未読タブでのみ表示
    return this.searchMode == "1" || this.searchMode == "2";
  }

  @Watch("searchMode")
  watchSearchMode() {
    this.pushSearch();
  }

  async created() {
    if (!this.patientId) {
      //全体の24時間体制画面
      //検索履歴がある場合それを使う
      this.collection = COLLECTION_SEARCH_COND_FULLTIME;
      this.documentId = String(this.loginUser.id);
      const searchCond = (await this.documentGet()) as SearchCondition;
      if (searchCond) {
        this.searchCond = searchCond;

        // チェックボックスの形で Firestore に登録されていない場合は、以前の登録値から判断してチェックボックスの値をセット
        if (this.searchCond.patient_status === undefined) {
          const isEnabledOn = this.searchCond.status === 1;
          const isBreakOn =
            this.searchCond.status === 2 || this.searchCond.status === 3;
          const isEndOn =
            this.searchCond.status === 3 || this.searchCond.status === 4;
          this.searchCond.patient_status = {
            enabled: Number(isEnabledOn),
            break: Number(isBreakOn),
            end: Number(isEndOn)
          };
        }

        // 並び替えボタンのラベルを保存済み検索条件に合わせる
        const matchSortBy = this.sortRuleList.find(
          sortBy =>
            sortBy.sort === this.searchCond.sort &&
            sortBy.sortType === this.searchCond.sort_type
        );
        if (matchSortBy) this.sortBy = matchSortBy;
      } else if (searchCond === false) {
        // firestore取得失敗
        this.$openAlert(
          "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
        );
        return;
      }
      if (
        !this.searchCond.office_ids ||
        this.searchCond.office_ids.length === 0
      ) {
        this.searchCond.office_ids = this.DefaultOffice;
      }

      if (!this.searchCond.patient_ids) {
        this.searchCond.patient_ids = [];
      }
    }
    // 検索期間はfirestore保存済みデータを利用しない
    this.searchCond.start_yearmonth = "";
    this.searchCond.end_yearmonth = "";
    if (!this.$route.query.key || this.$route.query.key === DefaultSearchMode) {
      this.search(); //未読
    }
  }

  mounted() {
    this.realignDetailWidth();
    this.realignDetailSpace();
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.closeDetail(this.openingItemId);
    this.openingItemId = 0;
    this.realignDetailSpace();
  }

  /** 指定したIDの24時間体制項目 */
  private hour24ItemElem(hour24Id: number): Hour24Item | undefined {
    return (this.$refs[`hour24Item_${hour24Id}`] as Hour24Item[])?.[0];
  }

  private onResize() {
    const widthChanged =
      this.beforeRootWidth !== document.documentElement.clientWidth;
    this.beforeRootWidth = document.documentElement.clientWidth;
    if (widthChanged) {
      this.realignDetailWidth();
      this.$nextTick(() => {
        this.realignDetailSpace();
      });
    }
  }

  private openedDetail(hour24Id: number) {
    const isOpeningOther = this.openingItemId !== 0;
    if (isOpeningOther && this.openingItemId !== hour24Id) {
      // 同時に複数の詳細エリアは開かない
      this.closeDetail(this.openingItemId);
    }
    this.openingItemId = hour24Id;
    this.lastOpenItemId = hour24Id;
    this.$nextTick(() => {
      this.realignDetailSpace();
    });
  }

  private closeDetail(hour24Id: number) {
    const targetElem = this.hour24ItemElem(hour24Id);
    targetElem?.CloseDetail();
  }

  private closedDetail(hour24Id: number) {
    if (this.openingItemId === hour24Id) {
      this.openingItemId = 0;
      this.realignDetailSpace();
    }
  }

  /** メインエリアの表示幅はpx指定なので、リサイズ時には24時間体制詳細エリアの幅分空ける */
  private realignDetailSpace() {
    const mainElem = document.querySelector(".v-main") as HTMLElement;
    if (mainElem == null) {
      return; // スタイル調整対象の要素が無いので以降の処理ができない。
    }
    mainElem.style.transitionDuration = "0s";
    if (!this.isOverlayDetail && this.openingItemId !== 0) {
      mainElem.style.paddingRight = this.detailPanelWidth;
    } else {
      mainElem.style.paddingRight = "0px";
    }
    // 50ミリ秒間だけ幅調整アニメーションを無効にして、一瞬でメイン領域の幅を変えることで描画を軽く
    // $nextTickでは0ミリ秒で呼び出されてしまったので、setTimeoutを使う
    setTimeout(() => {
      mainElem.style.transitionDuration = "";
    }, 50);
  }

  /** 詳細エリアの幅を再設定 */
  private realignDetailWidth() {
    // 縦向きiPad mini(768px幅)でも右サイド表示になるように、幅の広いPC幅なら500pxよりももっと広げられる
    const rootWidth = document.documentElement.clientWidth;
    if (rootWidth >= this.$vuetify.breakpoint.thresholds.md) {
      this.detailPanelWidth = Math.ceil(rootWidth * 0.375) + "px";
    } else if (rootWidth >= 750) {
      this.detailPanelWidth = Math.ceil(rootWidth * 0.5) + "px";
    } else {
      this.detailPanelWidth = "500px";
    }
  }

  /** ソート方法を変更した時 */
  private changeSort(sortRule: SortTypeSelect) {
    this.sortBy = sortRule;
    this.searchCond.sort = sortRule.sort;
    this.searchCond.sort_type = sortRule.sortType;

    //検索実行
    this.pushSearch();
  }

  /** 読み込み終了処理 */
  private finishLoaded(readItems: unknown[]) {
    this.infiniteLoading?.stateChanger.loaded();
    if (readItems?.length < this.limit) {
      this.infiniteLoading?.stateChanger.complete();
    }
    this.page++;
  }

  /** 読み込みリセット処理 */
  private resetLoaded() {
    this.infiniteLoading?.stateChanger.reset();
    this.page = 0;
    this.fulltimeItems = [];
  }

  /** 今月の取得 */
  private getThisMonth(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    const today = date.toISOString().substr(0, 7);
    return today;
  }

  /** 前月の取得 */
  private getLastmonth(): string {
    const date = new Date();
    date.setDate(1); // 当月の1日にする
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 1);
    const lastmonth = date.toISOString().substr(0, 7);
    return lastmonth;
  }

  /** タグの色取得 */
  private getTagColor(category: string): string {
    let color = "primary";
    if (category == "クレーム") {
      color = "secondary";
    }
    return color;
  }

  /** デフォルト検索条件：事業所 */
  private get DefaultOffice() {
    if (this.loginUser.office_id !== 0) {
      return [this.loginUser.office_id];
    }
    return [this.HeadOffice];
  }

  /** 絞り込みしているかどうか */
  private get IsFiltered(): boolean {
    const defaultCond = DefaultSearchCondition();
    if (this.patientId) {
      return !(
        this.searchCond.start_yearmonth === "" &&
        this.searchCond.end_yearmonth === "" &&
        this.searchCond.selected_category_items.length ===
          defaultCond.selected_category_items.length
      );
    } else {
      const isDefaultPatientStatus =
        this.searchCond.patient_status.enabled === 1 &&
        this.searchCond.patient_status.break === 0 &&
        this.searchCond.patient_status.end === 0;

      return !(
        JSON.stringify(this.searchCond.office_ids) ===
          JSON.stringify(this.DefaultOffice) &&
        this.searchCond.start_yearmonth === "" &&
        this.searchCond.end_yearmonth === "" &&
        this.searchCond.selected_category_items.length ===
          defaultCond.selected_category_items.length &&
        this.searchCond.keyword === "" &&
        isDefaultPatientStatus &&
        !this.searchCond.only_care_emergency &&
        !this.searchCond.only_medical_emergency &&
        !this.searchCond.group_ids.find(id => id !== 0 && id !== null) &&
        this.searchCond.group_operator === 1
      );
    }
  }

  /** 利用者リストによる検索が可能か */
  private get CanPatientSearch(): boolean {
    if (this.searchCond.patient_ids.length != this.beforePatientIds.length) {
      return true;
    }

    if (this.searchCond.patient_ids.length == 0) {
      return false;
    }

    let isSamePatientIds = true;
    this.searchCond.patient_ids.forEach((patientId: number) => {
      if (!this.beforePatientIds.includes(patientId)) {
        isSamePatientIds = false;
      }
    });
    return !isSamePatientIds;
  }

  /** 検索条件リセット処理 */
  private resetSearchCond() {
    const defaultCond = DefaultSearchCondition();
    defaultCond.start_yearmonth = "";
    defaultCond.end_yearmonth = "";
    defaultCond.office_ids = this.DefaultOffice;
    this.searchCond = defaultCond;
  }

  /** 検索ボタンクリック時の処理 */
  private async pushSearch() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      this.SearchPanel = true;
      return;
    }

    // 利用者リストをリセット(全体24時間体制画面の場合のみ)
    if (!this.patientId && this.patientSelect) {
      this.patientSelect.resetSearchInput();
      this.patientSelect.resetCachedItems();
    }

    this.resetLoaded();

    // 詳細エリアは閉じる
    this.closeDetail(this.openingItemId);
    // 検索時はHour24ItemがDOMから消され、CloseDetailが実行されない
    this.openingItemId = 0;
    this.lastOpenItemId = 0;
    this.realignDetailSpace();

    this.search();
  }

  /* 検索処理 */
  private search() {
    let isReading = 2;
    this.searchCond.only_favorite_flg = false;
    switch (this.searchMode) {
      case DefaultSearchMode:
        //すべて
        break;
      case "2":
        //未読
        isReading = 0;
        break;
      case "3":
        //既読
        isReading = 1;
        break;
      case "4":
        //お気に入り
        this.searchCond.only_favorite_flg = true;
        break;
    }

    this.searchCond.patient_id = this.patientId;
    // empty対策
    console.log(this.searchCond.group_ids);
    this.readingItem = DefaultBoardItem();
    this.postJsonCheck(
      window.base_url + "/api/fulltime/search",
      {
        search_cond: {
          ...this.searchCond,
          patient_status: this.patientId
            ? {
                enabled: 0,
                break: 0,
                end: 0
              }
            : this.searchCond.patient_status,
          part: {
            patient_id: this.searchCond.patient_id,
            keyword: this.searchCond.keyword,
            office_ids: this.searchCond.office_ids,
            group_ids: this.searchCond.group_ids.filter(Boolean),
            group_operator: this.searchCond.group_operator
          }
        },
        is_reading: isReading, //0:未読 1:既読
        limit: this.limit,
        page: this.page
      },
      res => {
        if (!this.page) {
          this.fulltimeItems = res.data.all_items;
        } else {
          this.fulltimeItems = this.fulltimeItems.concat(res.data.all_items);
        }
        this.$nextTick(() => {
          this.realignDetailSpace();
        });
        this.staffId = res.data.staff_id;
        this.groups = res.data.groups;

        // 検索結果から作成された利用者リスト
        const resPatientList: Choice[] = res.data.patient_list;
        // 検索結果から作成された利用者ID
        const resPatientIds: number[] = res.data.patient_ids;

        // 選択した利用者の24時間体制が存在しなかった場合でも、検索結果として残すためにリストに追加しておく
        this.patientList.forEach((patientChoice: Choice) => {
          const patientChoiceId = Number(patientChoice.value);

          if (
            this.searchCond.patient_ids.includes(patientChoiceId) &&
            !resPatientIds.includes(patientChoiceId)
          ) {
            resPatientList.unshift(patientChoice);
          }
        });

        this.patientList = resPatientList;
        this.beforePatientIds = this.searchCond.patient_ids;

        this.updateStartEnd({ start: res.data.start, end: res.data.end });
        if (!this.patientId) {
          //全体の24時間体制画面
          this.documentSave(this.searchCond);
        }
        this.finishLoaded(res.data.all_items);
      }
    );
  }

  /** 一括既読処理 */
  private async batchRead() {
    if (
      !(await this.$openConfirm(
        "未読を全て既読にします。よろしいですか？\n※絞り込み条件に関わらず、全て既読になります\n※未読のコメントも既読になります"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/fulltime/read/batch",
      { patient_id: this.patientId },
      () => {
        // 処理後は再検索により最新の状態に画面を更新する
        this.pushSearch();
      }
    );
  }

  private get StartYearMonthLable() {
    if (this.searchCond.start_yearmonth) {
      return "開始月";
    }
    return "期間未選択";
  }

  private get EndYearMonthLable() {
    if (this.searchCond.end_yearmonth) {
      return "終了月";
    }
    return "期間未選択";
  }

  //-----------------------------------------------------
  // Chack
  //-----------------------------------------------------
  //開始日:日付チェック
  private chackStart(): boolean | string {
    //開始日が終了日より後
    if (
      this.searchCond.start_yearmonth != "" &&
      this.searchCond.end_yearmonth != "" &&
      this.searchCond.start_yearmonth > this.searchCond.end_yearmonth
    ) {
      return "開始日が終了日より後になっています";
    }
    return true;
  }
  //終了日:日付チェック
  private chackEnd(): boolean | string {
    //終了日が開始日より前
    if (
      this.searchCond.start_yearmonth != "" &&
      this.searchCond.end_yearmonth != "" &&
      this.searchCond.start_yearmonth > this.searchCond.end_yearmonth
    ) {
      return "終了日が開始日より前になっています";
    }
    return true;
  }
  //片方の入力を許可しない
  private checkPeriod(): boolean | string {
    if (
      this.searchCond.start_yearmonth != "" &&
      this.searchCond.end_yearmonth != ""
    ) {
      return true;
    } else if (
      this.searchCond.start_yearmonth == "" &&
      this.searchCond.end_yearmonth == ""
    ) {
      return true;
    } else {
      if (this.searchCond.start_yearmonth == "") {
        return "終了月のみでの検索はできません。";
      } else {
        return "開始月のみでの検索はできません。";
      }
    }
  }

  //事業所選択
  private checkOfficeSelect(): boolean | string {
    //1つも選択していない場合
    if (!this.searchCond.office_ids || this.searchCond.office_ids.length == 0) {
      return "1事業所は選択してください";
    }
    //5つ以上選択している場合
    if (!this.searchCond.office_ids || this.searchCond.office_ids.length > 5) {
      return "最大5事業所まで選択可能です";
    }
    return true;
  }
}
