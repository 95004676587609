

















































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { CalendarEvent } from "vuetify";
import {
  COLLECTION_STAFF_CALENDAR_SETTING,
  COLLECTION_STAFF_CALENDAR_SET_COLORS
} from "@/const/envFireStore";
import { Staff } from "#/model/staff";
import * as appDate from "#/utility/appDate";
import { firebase } from "@/firebase";

import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";

import Navigation from "@/components/calendar/Navigation.vue";
import SearchStaffDialog from "@/components/calendar/SearchStaffDialog.vue";
import EventDialog from "@/components/common_ibow/calendar/StaffScheduleEditEventDialog.vue";
import CalendarFocusChange from "#/components/calendar/CalendarFocusChange.vue";
import ShowEventDialog from "@/components/common_ibow/calendar/StaffScheduleShowEventDialog.vue";
import {
  CalendarDispEvent,
  EditRangeDiv,
  Layer
} from "#/components/calendar/common";
import {
  Document,
  DefaultDocument,
  SetColor,
  DefaultDocumentSettingColor,
  SelectStaff,
  DOCUMENT_EVENT_TYPE
} from "@/components/calendar/common";
import NowButton from "#/components/calendar/NowButton.vue";
import CalendarTitleBeforeAfter from "#/components/calendar/CalendarTitleBeforeAfter.vue";
import CalendarTitle from "@/components/calendar/CalendarTitle.vue";
import IbowCalendar from "@/components/common_ibow/calendar/IbowCalendar.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    Navigation,
    SearchStaffDialog,
    EventDialog,
    ShowEventDialog,
    CalendarFocusChange,
    CalendarTitleBeforeAfter,
    NowButton,
    CalendarTitle,
    IbowCalendar
  }
})
export default class StaffCalendar extends Mixins(
  AxiosMixin,
  FireStoreMixin,
  UtilMixin
) {
  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: IbowCalendar;

  /** イベントコンポーネント */
  @Ref("event") private readonly event!: EventDialog;

  /** ナビゲーションコンポーネント */
  @Ref("navigation") private readonly navigation!: Navigation;

  /** 表示用イベントコンポーネント */
  @Ref("showEvent") private readonly showEvent!: ShowEventDialog;

  /** 検索条件のWatch */
  @Watch("document", { deep: true }) private watchDocument() {
    this.saveCalendarDocument();
  }

  /** 職員一覧 */
  private staffs: Staff[] = [];
  /** カレンダーイベント */
  private events: CalendarDispEvent[] = [];
  /** その他レイヤー */
  private other_layers: Layer[] = [];
  /** 選択職員情報（firestore） */
  private document = DefaultDocument();
  /** 職員色情報（firestore） */
  private documentSetColor = DefaultDocumentSettingColor();
  /** 対象年月日 */
  private focus = this.dateToStr(new Date(), "yyyy-MM-dd");
  /** 選択職員情報のFirestoreオブジェクト */
  private docInstance: firebase.firestore.DocumentReference | boolean = false;
  /** 職員色情報のFirestoreオブジェクト */
  private colorInstance: firebase.firestore.DocumentReference | boolean = false;
  /** 職員色情報が取得できたかどうか */
  private resGetColor = true;
  /** 選択職員情報が取得できたかどうか */
  private resGetDoc = true;
  /** ログイン職員のレイヤーID */
  private loginStaffLayerId = 0;

  private eventType = EventType;

  /** ログインユーザーの職員ID */
  private get LoginStaffId() {
    return this.loginUser.id;
  }

  /** ログインユーザーのiBowシステムID */
  private get LoginSysId() {
    return String(this.loginUser.ewell_user_system_id);
  }

  /** 予定の職員ID配列 */
  private get SelectCalendarIds() {
    return this.getIds(DOCUMENT_EVENT_TYPE.STAFF);
  }

  /** 訪問の職員ID配列 */
  private get SelectVisitIds() {
    return this.getIds(DOCUMENT_EVENT_TYPE.VISIT);
  }

  /** シフトの職員ID配列 */
  private get SelectShiftIds() {
    return this.getIds(DOCUMENT_EVENT_TYPE.SHIFT);
  }

  /** フィルターされたイベント */
  private get FilterEvent(): CalendarDispEvent[] {
    const selectLayerIds = this.document.selectLayers
      .filter(layer => layer.checked)
      .map(layer => layer.layerId);
    const staffIds = this.SelectCalendarIds;
    const visitIds = this.SelectVisitIds;
    const shiftIds = this.SelectShiftIds;

    return this.events.filter(event => {
      switch (event.type) {
        case EventType.Holiday:
        case EventType.AccountSchedule:
          // 国民の祝日とマイカレンダーのイベント
          if (selectLayerIds.includes(event.layer_id)) {
            return true;
          }
          break;
        case EventType.StaffSchedule:
          // 職員予定のイベント
          if (staffIds.includes(event.staff_id)) {
            return true;
          }
          break;
        case EventType.VisitSchedule:
        case EventType.VisitScheduleNoStaff:
          // 訪問予定のイベント
          if (visitIds.includes(event.staff_id)) {
            return true;
          }
          break;
        case EventType.Shift:
        case EventType.AttendanceShift:
          // シフトのイベント
          if (shiftIds.includes(event.staff_id)) {
            return true;
          }
          break;
      }
      return false;
    });
  }

  public async created() {
    //職員一覧を取得する
    await this.fetchStaffs();

    //職員カレンダーdocument取得;
    await this.getCalendarDocument();

    //各職員カレンダー・訪問予定の表示変更（初回は色情報を取得する）
    this.fetchCalendar(true);
  }

  /** カレンダー情報を取得する */
  private fetchCalendar(wantColorDoc = false) {
    this.postJsonCheck(
      window.base_url + "/api/calendar/layers/get",
      {
        target_ym: this.focus.substring(0, 7),
        staff_ids: this.SelectCalendarIds,
        staff_visit_ids: this.SelectVisitIds,
        staff_shift_ids: this.SelectShiftIds
      },
      async res => {
        if (res.data) {
          this.events = res.data.events;
          this.other_layers = res.data.other_layers;

          this.loginStaffLayerId = res.data.login_staff_layer_id;

          // 取得のAPIで職員色情報が更新されるため、更新後の色情報を取得する
          if (wantColorDoc) {
            await this.getColorDocument();
          }

          // 管理者ログインの場合
          if (!this.LoginSysId) {
            // ナビゲーション内のチップカラー設定
            this.documentSetColor.setting = res.data.admin_color_setting;
          }
        }
      }
    );
  }

  /** 職員一覧を取得する */
  private async fetchStaffs() {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/calendar/staffs/get",
        {},
        res => {
          if (res.data) {
            this.staffs = res.data.staffs;
            return resolve(true);
          } else {
            resolve(false);
          }
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  /** イベント追加ボタンクリック時 */
  private clickAddEvent() {
    const d = new Date();
    const date = appDate.dateToStr(d, "yyyy-MM-dd");
    this.calendar.staffScheduleEditEventDialog.open(
      0,
      date,
      EditRangeDiv.New,
      [],
      d.getHours(),
      d.getMinutes()
    );
  }

  /** カレンダークリック */
  private clickCalendar(e: CalendarEvent) {
    this.calendar.staffScheduleEditEventDialog.open(
      0,
      e.date,
      EditRangeDiv.New,
      [],
      e.hour,
      e.minute
    );
  }

  /** 削除済み職員を除外する */
  private filterDeletedStaff(): SelectStaff[] {
    return this.document.showStaffs.filter(target => {
      if (this.LoginStaffId === target.id) {
        return true;
      }
      if (this.staffs.findIndex(staff => staff.id === target.id) !== -1) {
        return true;
      }
    });
  }

  /** firestoreから色情報を取得する */
  private async getColorDocument() {
    if (this.LoginSysId) {
      /** インスタンス、データ取得 */
      this.loading++;
      const res = await this.getInstanceDocument(
        COLLECTION_STAFF_CALENDAR_SET_COLORS,
        String(this.LoginSysId)
      );
      this.loading--;

      if (typeof res === "boolean") {
        this.resGetColor = false;
        this.$openAlert(
          "カレンダーの色情報が正しく取得できませんでした。画面を更新して再度お試しください。"
        );
        return;
      }

      this.resGetColor = true;
      this.colorInstance = res.instance;
      const dataColor = res.data;

      if (dataColor) {
        this.documentSetColor = dataColor as SetColor;
      } else if (dataColor === undefined) {
        this.documentSetColor = DefaultDocumentSettingColor();
        await this.documentSaveByIns(this.colorInstance, this.documentSetColor);
      }
    } else {
      // 管理者ログイン対応
      this.documentSetColor = DefaultDocumentSettingColor();
    }
    return true;
  }

  /** firestoreから職員情報を取得する */
  private async getCalendarDocument() {
    /** インスタンス取得 */
    this.loading++;
    const res = await this.getInstanceDocument(
      COLLECTION_STAFF_CALENDAR_SETTING,
      String(this.LoginStaffId)
    );
    this.loading--;

    if (typeof res === "boolean") {
      this.resGetDoc = false;
      this.$openAlert(
        "カレンダーの画面情報が正しく取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }

    this.docInstance = res.instance;
    const data = res.data;
    this.resGetDoc = true;

    if (data) {
      this.document = data as Document;
      if (!this.document.allDisp) {
        this.document.allDisp = [0, 1, 2];
      }
      this.document.showStaffs.forEach((staff, i) => {
        if (staff.display == undefined) {
          this.document.showStaffs[i].display = true;
        }
      });
    } else if (data === undefined) {
      this.document = DefaultDocument();
    }

    if (
      this.document.showStaffs.length === 0 ||
      this.document.showStaffs.filter(item => item.id === this.LoginStaffId)
        .length === 0
    ) {
      this.document.showStaffs.push({
        id: this.LoginStaffId,
        checked: [0, 1, 2],
        color: "",
        display: true
      });
    }

    // API職員一覧取得（成功した場合のみ、削除済職員の除外を行う/上書き防止）
    if (this.staffs && this.staffs.length > 0) {
      // 表示職員内に削除済の職員がいる場合は除外
      this.document.showStaffs = this.filterDeletedStaff();
    }

    // 初期表示の表示職員の保存漏れ対策として、fetch前にかならず保存させる
    await this.saveCalendarDocument();

    return true;
  }

  /** カレンダー保存 */
  private async saveCalendarDocument() {
    if (this.resGetDoc) {
      if (typeof this.docInstance !== "boolean") {
        await this.documentSaveByIns(this.docInstance, this.document);
      }
    }
  }

  /** ID配列の取得 */
  private getIds(type: number): number[] {
    return this.document.showStaffs
      .filter(staff => {
        if (staff.display) {
          if (typeof staff.checked === "boolean") {
            return staff.checked;
          } else {
            return staff.checked.includes(type);
          }
        }
      })
      .map(staff => staff.id);
  }
}
