
































import { Component } from "vue-property-decorator";
import RegularPartBase from "./RegularPartBase";

@Component
export default class EveryTime extends RegularPartBase {
  created(): void {
    this.setNursingLeaveInstFlg();
  }

  //退院時共同指導日
  protected setNursingLeaveInstFlg() {
    if (
      this.receiptVisitRecord.nursing_leave_inst_date != "" ||
      this.receiptVisitRecord.nursing_leave_inst_date2 != ""
    ) {
      this.nursing_leave_inst_flg = true;
    }
  }

  //退院時共同指導日1（必須入力）
  protected isNeedNursingLeaveInstDate1(): boolean {
    if (this.nursing_leave_inst_flg) {
      return true;
    }
    return false;
  }

  //退院時共同指導日1（日にち被り不可）
  protected chackNursingLeaveInstDate1(): boolean | string {
    //日にち被り不可
    if (
      this.receiptVisitRecord.nursing_leave_inst_date != "" &&
      this.receiptVisitRecord.nursing_leave_inst_date ==
        this.receiptVisitRecord.nursing_leave_inst_date2 &&
      !this.disableCheckNursingLeaveInstDate()
    ) {
      return "退院時共同指導日に同日付が入力されています";
    }
    return true;
  }

  //退院時共同指導日2（日にち被り不可）
  protected chackNursingLeaveInstDate2(): boolean | string {
    //日にち被り不可
    if (
      this.receiptVisitRecord.nursing_leave_inst_date != "" &&
      this.receiptVisitRecord.nursing_leave_inst_date ==
        this.receiptVisitRecord.nursing_leave_inst_date2 &&
      !this.disableCheckNursingLeaveInstDate()
    ) {
      return "退院時共同指導日に同日付が入力されています";
    }
    return true;
  }

  //退院時共同指導日
  protected disableCheckNursingLeaveInstDate(): boolean {
    let result = false;
    if (
      !this.nursing_leave_inst_flg ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //退院時共同指導日
    if (this.disableCheckNursingLeaveInstDate()) {
      this.receiptVisitRecord.nursing_leave_inst_date = "";
      this.receiptVisitRecord.nursing_leave_inst_date2 = "";
    }
  }
}
