







































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Staff } from "#/model/staff";

@Component
export default class SearchStaffDialog extends Vue {

  /** IDプレフィックス */
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** 職員リスト */
  @Prop({ default: () => [] }) staffs!: Staff[];

  /** 単体選択かどうか（開催者選択かどうか） */
  @Prop({ default: false }) singleSelect!: boolean;

  /** ダイアログタイトル */
  @Prop({ default: "職員を追加する" }) title!: string;

  /** 保存ボタンラベル */
  @Prop({ default: "追加する" }) saveLabel!: string;

  /** 選択した職員IDの配列を返す */
  @Emit() private add(selectIds: number[]): number[] {
    return selectIds;
  }

  /** ダイアログ展開状態 */ private dialog = false;

  /** チェック状態 */ private selected: Staff[] = [];

  /** 検索テキスト */ private search = "";

  /** テーブルヘッダー */ private headers = [
    {
      text: "職員名",
      value: "info.ibow_nickname",
      sortable: true
    },
    {
      text: "職員ID",
      value: "info.office_staff_id",
      sortable: true
    },
    {
      text: "所属",
      value: "office.name",
      sortable: true
    },
    {
      text: "職種",
      value: "info.staff_div_name",
      sortable: true
    }
  ];

  /** ダイアログ開く */ public open() {
    this.selected = [];
    this.search = "";
    this.dialog = true;
  }

  /** キャンセル */ private cancel() {
    this.dialog = false;
  }

  /** 保存する */ private save() {
    const selectIds = this.selected.map(select => select.id);

    if (this.singleSelect && selectIds.length === 0) {
      this.$openAlert("開催者を選択してください。");
      return;
    }

    this.dialog = false;
    this.add(selectIds);
  }
}
