























































































































































import { Component, Watch } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class EveryTime extends MedPartBase {
  created(): void {
    this.setTimesDiv();
    this.setKinouKyokaDiv();
  }

  //複数回区分
  protected setTimesDiv() {
    if (this.receiptVisitRecord.times_div == 2) {
      this.times_div2 = true;
      this.times_div3 = false;
    }
    if (this.receiptVisitRecord.times_div == 3) {
      this.times_div2 = false;
      this.times_div3 = true;
    }
  }

  //機能強化区分
  protected setKinouKyokaDiv() {
    if (this.receiptVisitRecord.kinou_kyoka_div == 1) {
      this.kinou_kyoka_div1 = true;
    }
    if (this.receiptVisitRecord.kinou_kyoka_div == 2) {
      this.kinou_kyoka_div2 = true;
    }
  }

  /** 複数回区分2回 */
  protected times_div2 = false;

  /** 複数回区分3回 */
  protected times_div3 = false;

  /** 機能強化区分1 */
  protected kinou_kyoka_div1 = false;

  /** 機能強化区分1 */
  protected kinou_kyoka_div2 = false;

  //複数回訪問タイトル
  protected get titleMultipleVisits(): string {
    let title = "難病等複数回訪問";
    if (this.event.visit_record.mental_div == 2) {
      title = "精神科複数回訪問";
    }
    return title;
  }

  //複数回区分2回
  @Watch("times_div2") protected handleTimesDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.times_div2 == true) {
      this.receiptVisitRecord.times_div = 2;
      this.times_div3 = false;
    }
    if (this.times_div2 == false && this.times_div3 == false) {
      this.receiptVisitRecord.times_div = 0;
    }
  }

  //複数回区分3回
  @Watch("times_div3") protected handleTimesDiv3() {
    if (!this.isLoaded) {
      return;
    }
    if (this.times_div3 == true) {
      this.receiptVisitRecord.times_div = 3;
      this.times_div2 = false;
    }
    if (this.times_div2 == false && this.times_div3 == false) {
      this.receiptVisitRecord.times_div = 0;
    }
  }

  //機能強化区分1
  @Watch("kinou_kyoka_div1") protected handleKinouKyokaDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.kinou_kyoka_div1 == true) {
      this.receiptVisitRecord.kinou_kyoka_div = 1;
    }
    if (this.kinou_kyoka_div1 == false) {
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
  }
  //機能強化区分2
  @Watch("kinou_kyoka_div2") protected handleKinouKyokaDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.kinou_kyoka_div2 == true) {
      this.receiptVisitRecord.kinou_kyoka_div = 2;
    }
    if (this.kinou_kyoka_div2 == false) {
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
  }

  //提供時間
  protected disableCheckMedicalTimeZoneDiv(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //複数回訪問:回数
  protected disableCheckTimesDiv(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //複数名訪問:職員種別
  protected disableCheckAccompaniedStaffDiv(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //機能強化型訪問看護管理療養費1
  protected disableCheckKinouKyokaDiv1(): boolean {
    let result = false;
    if (this.officeHistory.medical_kinou_kyoka_ryouyouhi1 == 0) {
      result = true;
    }
    return result;
  }

  //機能強化型訪問看護管理療養費2
  protected disableCheckKinouKyokaDiv2(): boolean {
    let result = false;
    if (this.officeHistory.medical_kinou_kyoka_ryouyouhi2 == 0) {
      result = true;
    }
    return result;
  }

  //退院時共同指導
  protected disableCheckMedicalLeaveInstFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //退院支援指導
  protected disableCheckLeaveSupportFlg(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.remedy_div == 4 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //退院日
  protected disableCheckLeaveSupportDate(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.leave_support_flg == 0 ||
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.remedy_div == 4 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //提供時間
    if (this.disableCheckMedicalTimeZoneDiv()) {
      this.receiptVisitRecord.medical_time_zone_div = 0;
    }
    //複数回訪問:回数
    if (this.disableCheckTimesDiv()) {
      this.times_div2 = false;
      this.times_div3 = false;
      this.receiptVisitRecord.times_div = 0;
    }
    //複数名訪問:職員種別
    if (this.disableCheckAccompaniedStaffDiv()) {
      this.receiptVisitRecord.accompanied_staff_div = 0;
    }
    //機能強化型訪問看護管理療養費1
    if (this.disableCheckKinouKyokaDiv1() && !this.kinou_kyoka_div2) {
      this.kinou_kyoka_div1 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費2
    if (this.disableCheckKinouKyokaDiv2() && !this.kinou_kyoka_div1) {
      this.kinou_kyoka_div2 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //退院時共同指導
    if (this.disableCheckMedicalLeaveInstFlg()) {
      this.receiptVisitRecord.medical_leave_inst_flg = 0;
    }
    //退院支援指導
    if (this.disableCheckLeaveSupportFlg()) {
      this.receiptVisitRecord.leave_support_flg = 0;
    }
    //退院日
    if (this.disableCheckLeaveSupportDate()) {
      this.receiptVisitRecord.leave_support_date = "";
    }
  }
}
