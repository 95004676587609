








































































































































































































import { Component, Watch } from "vue-property-decorator";
import EveryTimeV3 from "./EveryTimeV3.vue";

@Component
export default class EveryTimeV4 extends EveryTimeV3 {
  created(): void {
    this.setTimesDiv();
    this.setKinouKyokaDivV2();
    this.setMentalSupportDiv();
    this.setLeaveSupportFlg();
  }

  //退院支援指導
  protected setLeaveSupportFlg() {
    if (this.receiptVisitRecord.leave_support_flg == 1) {
      this.leave_support_flg = true;
    }
    if (this.receiptVisitRecord.leave_support_flg == 2) {
      this.leave_support_long_flg = true;
    }
  }

  /** 退院支援指導 */
  protected leave_support_flg = false;

  /** 退院支援指導（長時間） */
  protected leave_support_long_flg = false;

  //複数名職員種別（オーバーライド）
  @Watch("receiptVisitRecord.accompanied_staff_div")
  protected handleAccompaniedStaffDiv() {
    if (!this.isLoaded) {
      return;
    }
    if (this.receiptVisitRecord.accompanied_staff_div == 0) {
      this.receiptVisitRecord.accompanied_hojo_staff_div = 0;
    }
  }

  //退院支援指導
  @Watch("leave_support_flg") protected handleLeaveSupportFlg() {
    if (!this.isLoaded) {
      return;
    }
    if (this.leave_support_flg == true) {
      this.leave_support_long_flg = false;
      this.receiptVisitRecord.leave_support_flg = 1;
    }
    if (
      this.leave_support_flg == false &&
      this.leave_support_long_flg == false
    ) {
      this.receiptVisitRecord.leave_support_flg = 0;
    }
  }
  //退院支援指導
  @Watch("leave_support_long_flg") protected handleLeaveSupportLongFlg() {
    if (!this.isLoaded) {
      return;
    }
    if (this.leave_support_long_flg == true) {
      this.leave_support_flg = false;
      this.receiptVisitRecord.leave_support_flg = 2;
    }
    if (
      this.leave_support_long_flg == false &&
      this.leave_support_flg == false
    ) {
      this.receiptVisitRecord.leave_support_flg = 0;
    }
  }

  //複数名訪問:（ニ）（ハ）
  protected disableCheckAccompaniedHojoStaffDivV4(): boolean {
    let result = false;
    if (
      this.event.visit_record.mental_div == 2 ||
      this.receiptVisitRecord.accompanied_staff_div == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //複数名訪問:回数区分
  protected disableCheckAccompaniedStaffCntV4(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.accompanied_staff_div == 0 ||
      (this.event.visit_record.mental_div == 1 &&
        this.receiptVisitRecord.accompanied_hojo_staff_div != 2) ||
      (this.event.visit_record.mental_div == 2 &&
        (this.receiptVisitRecord.accompanied_staff_div == 4 ||
          this.receiptVisitRecord.accompanied_staff_div == 6 ||
          this.receiptVisitRecord.accompanied_staff_div == 7 ||
          this.receiptVisitRecord.accompanied_staff_div == 9)) ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //長時間
  protected disableCheckLongTimeFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //長時間
  protected chackLongTimeFlg(): boolean | string {
    //90分未満の訪問は長時間加算できない
    const start = new Date(
      this.event.visit_record.service_start_datetime.replace(/-/g, "/")
    );
    const end = new Date(
      this.event.visit_record.service_end_datetime.replace(/-/g, "/")
    );
    const diff = Math.abs((end.getTime() - start.getTime()) / (60 * 1000));
    if (
      this.leave_support_long_flg &&
      diff < 91 &&
      !this.disableCheckLongTimeFlg()
    ) {
      return "訪問時間が長時間訪問看護に該当されません";
    }
    return true;
  }
  
  //複数名訪問:回数区分(1回目チェック用)
  protected disableCheckAccompaniedStaffCntV4Cnt1(): boolean {
    // 精神科訪問の同行者が看護補助(PSW等)の場合でもdisabledしない
    // それ以外の挙動は変えない
    if (
      (this.event.visit_record.mental_div == 2 &&
        (this.receiptVisitRecord.accompanied_staff_div == 4 ||
          this.receiptVisitRecord.accompanied_staff_div == 6 ||
          this.receiptVisitRecord.accompanied_staff_div == 7 ||
          this.receiptVisitRecord.accompanied_staff_div == 9)) ||
      this.baseDisableCheck()
    ) {
      return false;
    } else {
      return this.disableCheckAccompaniedStaffCntV4();
    }
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV4() {
    //提供時間
    if (this.disableCheckMedicalTimeZoneDiv()) {
      this.receiptVisitRecord.medical_time_zone_div = 0;
    }
    //複数回訪問:回数
    if (this.disableCheckTimesDivV3()) {
      this.times_div2 = false;
      this.times_div3 = false;
      this.receiptVisitRecord.times_div = 0;
    }
    //複数回訪問:同一建物内（保存時のみ0にする特殊ケース）
    if (this.disableCheckTimesThreepersonDiv()) {
      this.receiptVisitRecord.times_threeperson_div = 0;
    }
    //複数名訪問:職員種別
    if (this.disableCheckAccompaniedStaffDiv()) {
      this.receiptVisitRecord.accompanied_staff_div = 0;
    }
    //複数名訪問:（ニ）（ハ）
    if (this.disableCheckAccompaniedHojoStaffDivV4()) {
      this.receiptVisitRecord.accompanied_hojo_staff_div = 0;
    }
    //複数名訪問:回数区分
    if (this.disableCheckAccompaniedStaffCntV4()) {
      this.accompanied_staff_cnt1 = false;
      this.accompanied_staff_cnt2 = false;
      this.accompanied_staff_cnt3 = false;
      this.receiptVisitRecord.accompanied_staff_cnt = 0;
    }
    //複数名訪問:同一建物内（保存時のみ0にする特殊ケース）
    if (this.disableCheckAccompaniedStaffThreepersonDiv()) {
      this.receiptVisitRecord.accompanied_staff_threeperson_div = 0;
    }
    //機能強化型訪問看護管理療養費1
    if (
      this.disableCheckKinouKyokaDiv1() &&
      !this.kinou_kyoka_div2 &&
      !this.kinou_kyoka_div3
    ) {
      this.kinou_kyoka_div1 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費2
    if (
      this.disableCheckKinouKyokaDiv2() &&
      !this.kinou_kyoka_div1 &&
      !this.kinou_kyoka_div3
    ) {
      this.kinou_kyoka_div2 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //機能強化型訪問看護管理療養費3
    if (
      this.disableCheckKinouKyokaDiv3() &&
      !this.kinou_kyoka_div1 &&
      !this.kinou_kyoka_div2
    ) {
      this.kinou_kyoka_div3 = false;
      this.receiptVisitRecord.kinou_kyoka_div = 0;
    }
    //退院時共同指導
    if (this.disableCheckMedicalLeaveInstFlg()) {
      this.receiptVisitRecord.medical_leave_inst_flg = 0;
    }
    //退院支援指導
    if (this.disableCheckLeaveSupportFlg()) {
      this.receiptVisitRecord.leave_support_flg = 0;
    }
    //退院日
    if (this.disableCheckLeaveSupportDate()) {
      this.receiptVisitRecord.leave_support_date = "";
    }
  }
}
