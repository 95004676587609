

























































































import { Mixins, Component, Watch, Prop } from "vue-property-decorator";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import sanitizeHTML from "sanitize-html";
import { Choice } from "@/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

// チャートデータ
interface Chart {
  temp: string; // 体温
  pulse: number; // 脈
  blood_extends_right: number; // 血圧 拡張 右
  blood_contraction_right: number; // 血圧 収縮 右
  blood_extends_left: number; // 血圧 拡張 左
  blood_contraction_left: number; // 血圧 収縮 左
  breath: number; // 呼吸
  spo2: string; // SPO2
  consciousness_level: string; // 意識レベル
  blood_glucose_level: string; // 血糖値
  weight: string; // 体重
  waist_min: string; // 腹囲（臍上）
  waist_max: string; // 腹囲（最大）
  sputum: string; // 痰
  lung_sub_noise: string; // 肺副雑音
  life_autonomy_degree: string; // 生活自立度
}

// 観察項目
interface Observation {
  text: string; // 項目名
  value: string; // 測定値
}

// テーブルデータ
interface Record {
  date: string; // 測定日時
  chart: Chart;
  observations: Observation[]; // 観察項目
}

interface Progress {
  records: Record[];
}

interface ChartData {
  [key: string]: string[];
  date: string[];
}

interface ChartCustomColorType {
  legend: string; // 判例の色
  tableRow?: string; // 表の行ごとの背景色
}
interface ChartCustomColor {
  [key: string]: ChartCustomColorType;
}

const DefaultTableHeader = [
  {
    text: "測定日時",
    value: "date",
    isObs: false
  },
  {
    text: "体温",
    value: "temp",
    isObs: false
  },
  {
    text: "脈",
    value: "pulse",
    isObs: false
  },
  {
    text: "血圧収縮右",
    value: "blood_contraction_right",
    isObs: false
  },
  {
    text: "血圧拡張右",
    value: "blood_extends_right",
    isObs: false
  },
  {
    text: "血圧収縮左",
    value: "blood_contraction_left",
    isObs: false
  },
  {
    text: "血圧拡張左",
    value: "blood_extends_left",
    isObs: false
  },
  {
    text: "呼吸",
    value: "breath",
    isObs: false
  },
  {
    text: "SpO2",
    value: "spo2",
    isObs: false
  },
  {
    text: "意識レベル",
    value: "consciousness_level",
    isObs: false
  },
  {
    text: "血糖値",
    value: "blood_glucose_level",
    isObs: false
  },
  {
    text: "体重",
    value: "weight",
    isObs: false
  },
  {
    text: "腹囲（臍上）",
    value: "waist_min",
    isObs: false
  },
  {
    text: "腹囲（最大）",
    value: "waist_max",
    isObs: false
  },
  {
    text: "痰",
    value: "sputum",
    isObs: false
  },
  {
    text: "肺副雑音",
    value: "lung_sub_noise",
    isObs: false
  },
  {
    text: "生活自立度",
    value: "life_autonomy_degree",
    isObs: false
  }
];

@Component
export default class ProgressItems extends Mixins(
  UtilMixin,
  AxiosMixin,
  PatientMixin
) {
  @Prop({ default: 0 }) patientId!: number;
  @Prop({ default: true }) showChart!: boolean;
  @Prop({ default: true }) showObs!: boolean;
  @Prop({ default: false }) isPrint!: boolean;
  @Prop({ default: false }) showLabel!: boolean; // データラベル表示フラグ
  @Prop({ default: false }) showOutputDate!: boolean; // 出力日表示フラグ
  @Prop({ default: true }) showScroll!: boolean; // スライド表示フラグ
  @Prop({ default: "" }) officeName!: string; // 事業所名
  @Prop({ default: "" }) outputDate!: string; // 出力日

  private progress: Progress = { records: [] };
  private tableDataProgress: Progress = { records: [] };
  private chart!: am4charts.XYChart;
  public chartType = 0; // 表示期間
  public targetDate = this.dateToStr(new Date(), "yyyy-MM-dd");
  private sanitize = sanitizeHTML; // サニタイズ
  private showObservation = false;

  private tabs: Choice[] = [
    { value: 0, text: "前10回", other_string: "1" },
    { value: 1, text: "前1ヶ月のグラフ", other_string: "2" }
  ];
  // グラフ・表の色関連
  private chartColors: ChartCustomColor = {
    // 測定日時
    date: {
      legend: "",
      tableRow: "#e0e6ea"
    },
    // 体温：青系統
    temp: {
      legend: "#66b2ff",
      tableRow: "#bdf"
    },
    // 脈：赤系統
    pulse: {
      legend: "#f78585",
      tableRow: "#fcc"
    },
    // 呼吸：緑系統
    breath: {
      legend: "#0abb37",
      tableRow: "#cfc"
    },
    // 血圧右：黄系統
    bloodRight: {
      legend: "#d8d808",
      tableRow: "#ffc"
    },
    // 血圧左：橙系統
    bloodLeft: {
      legend: "#f5a80f",
      tableRow: "#ffedc8"
    }
  };

  public created(): void {
    // 印刷時はクエリ取得
    if (this.isPrint) {
      this.targetDate = String(this.$route.query.date);

      const idx = this.tabs.findIndex(
        tab => tab.other_string == this.$route.query.type
      );

      if (idx > -1) {
        this.chartType = Number(this.tabs[idx].value);
      }
    }
    this.getProgress();

    // 各項目グラフ・表の色を設定
    this.chartColors.blood_extends_right = this.chartColors.bloodRight;
    this.chartColors.blood_contraction_right = this.chartColors.bloodRight;
    this.chartColors.blood_extends_left = this.chartColors.bloodLeft;
    this.chartColors.blood_contraction_left = this.chartColors.bloodLeft;
  }

  public mounted(): void {
    this.setDefaultHeader();
    this.makeChart();
  }

  // チャートを作成する
  private makeChart() {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [
      {
        label: "...",
        menu: [
          {
            label: "Image",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
              { type: "pdf", label: "PDF" }
            ]
          }
        ]
      }
    ];

    // ロケール設定
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_ja_JP;
    chart.language.locale["_date_day"] = "MMMdd日";
    chart.language.locale["_date_year"] = "yyyy年";

    chart.paddingRight = 20;

    // 印刷時、チャートの高さはスクロール領域表示有無で変わる
    if (this.isPrint) {
      chart.height = this.showScroll ? 330 : 270;
    }

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    // dateAxis.renderer.minGridDistance = 0;

    // グラフカーソル
    chart.cursor = new am4charts.XYCursor();

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "top";
    chart.legend.contentAlign = "right";
    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;

    // チャート
    // 体温
    const temp = this.makeAxisX(
      chart,
      "体温",
      "temp",
      "℃",
      this.chartColors.temp.legend,
      this.makeAxisY(chart, "体温", { min: 34, max: 43 }, "axis-blue"),
      true,
      "circle"
    );

    // 脈
    const pulse = this.makeAxisX(
      chart,
      "脈",
      "pulse",
      "回／分",
      this.chartColors.pulse.legend,
      this.makeAxisY(chart, "脈", { min: 40, max: 175 }, "axis-red"),
      true,
      "circle"
    );
    // 呼吸
    const breath = this.makeAxisX(
      chart,
      "呼吸",
      "breath",
      "回／分",
      this.chartColors.breath.legend,
      this.makeAxisY(chart, "呼吸", { min: 5, max: 50 }, "axis-green"),
      true,
      "circle"
    );
    // 血圧
    const bloodY = this.makeAxisY(
      chart,
      "血圧",
      { min: 40, max: 220 },
      "axis-yellow"
    );
    const bloodExtendsRight = this.makeAxisX(
      chart,
      "血圧拡張右",
      "blood_extends_right",
      "mmHg",
      this.chartColors.bloodRight.legend,
      bloodY,
      false,
      "arrow-up"
    );
    const bloodContractionRight = this.makeAxisX(
      chart,
      "収縮右",
      "blood_contraction_right",
      "mmHg",
      this.chartColors.bloodRight.legend,
      bloodY,
      false,
      "arrow-down"
    );
    const bloodExtendsLeft = this.makeAxisX(
      chart,
      "血圧拡張左",
      "blood_extends_left",
      "mmHg",
      this.chartColors.bloodLeft.legend,
      bloodY,
      false,
      "arrow-up"
    );
    const blood_contraction_left = this.makeAxisX(
      chart,
      "収縮左",
      "blood_contraction_left",
      "mmHg",
      this.chartColors.bloodLeft.legend,
      bloodY,
      false,
      "arrow-down"
    );

    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;
    scrollbarX.series.push(temp);
    scrollbarX.series.push(pulse);
    scrollbarX.series.push(breath);
    scrollbarX.series.push(bloodExtendsRight);
    scrollbarX.series.push(bloodContractionRight);
    scrollbarX.series.push(bloodExtendsLeft);
    scrollbarX.series.push(blood_contraction_left);
    chart.scrollbarX.disabled = !this.showScroll;

    // tooltipを常時表示するか
    if (this.isPrint && this.showLabel) {
      this.setTooltipAlways(temp, "temp", "℃");
      this.setTooltipAlways(pulse, "pulse", "回／分");
      this.setTooltipAlways(breath, "breath", "回／分");
      this.setTooltipAlways(bloodExtendsRight, "bloodExtendsRight", "mmHg");
      this.setTooltipAlways(bloodExtendsLeft, "bloodExtendsLeft", "mmHg");
      this.setTooltipAlways(
        bloodContractionRight,
        "bloodContractionRight",
        "mmHg"
      );
      this.setTooltipAlways(
        blood_contraction_left,
        "blood_contraction_left",
        "mmHg"
      );
    }

    // データセット
    chart.data = this.makeChartData();

    // チャートセット
    this.chart = chart;
  }

  public beforeDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  @Watch("targetDate")
  private watchTargetDate() {
    if (this.targetDate) {
      this.getProgress();
    }
  }

  @Watch("showLabel")
  private watchShowLabel() {
    this.makeChart();
  }
  @Watch("showScroll")
  private watchShowScroll() {
    this.makeChart();
  }

  public get tableHeaders() {
    const headers = this.deepCopy(DefaultTableHeader);
    if (!this.showObservation) return headers;
    this.tableDataProgress.records.forEach(record => {
      record.observations.forEach(obs => {
        let include = false;
        for (let i = 0; i < headers.length; i++) {
          if (headers[i].value == obs.text) {
            include = true;
            break;
          }
        }
        if (!include)
          headers.push({ text: obs.text, value: obs.text, isObs: true });
      });
    });
    return headers;
  }

  public get tableDatas() {
    this.chartType; // リアクティブ用
    const datas: ChartData = { date: [] };
    this.tableDataProgress.records.forEach(record => {
      // 測定日時
      if (!datas.date) datas.date = [];
      let dateFormat = "M月dd日 HH:mm";
      let dateStr = "";
      if (this.ChartType == 2) {
        dateFormat = "M月dd日";
      }

      dateStr = this.dateToStr(
        new Date(record.date.replace(/-/g, "/")),
        dateFormat
      );

      dateStr = dateStr.replace(" ", "\n");
      datas.date.push(dateStr);

      //体温が入力されていないとき空欄にする
      if (record.chart.temp === "0") {
        record.chart.temp = "";
      }

      // チャートデータ
      for (const [key, val] of Object.entries(record.chart)) {
        if (!datas[key]) datas[key] = [];
        datas[key].push(val || "");
      }
      // 観察項目
      record.observations.forEach(obs => {
        if (!datas[obs.text]) datas[obs.text] = [];
        datas[obs.text].push(obs.value);
      });
    });
    return datas;
  }
  // 表中各行のカスタム背景色
  private get rowColor() {
    return (rowValue: string, cellValue: string | number) => {
      // ローディング中は背景色なし
      if (this.loading) {
        return false;
      }
      // データのないセルの背景色は設定しない
      if (cellValue.toString() === "") {
        return false;
      }
      return this.chartColors?.[rowValue]?.tableRow ?? false;
    };
  }

  // Y軸の設定
  private makeAxisY(
    chart: am4charts.XYChart,
    text: string,
    range: { min: number; max: number } | null,
    className: string
  ): am4charts.ValueAxis<am4charts.AxisRenderer> {
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 5; // y軸の幅
    valueAxis.title.text = text; // y軸のタイトル
    if (range) {
      valueAxis.min = range.min; // y軸の最小値
      valueAxis.max = range.max; // y軸の最大値
      // カスタム範囲でも強制的に小数点スマート調整を効かせる
      valueAxis.adjustLabelPrecision = false;
    }
    if (className) {
      valueAxis.userClassName = className; // y軸の背景色用クラス名
    }
    valueAxis.fontSize = 12;
    // @todo 軸ラベルの背景色をつけたい
    return valueAxis;
  }

  // X軸の設定
  private makeAxisX(
    chart: am4charts.XYChart,
    name: string,
    value: string,
    suffix: string,
    color: string,
    yAxis: am4charts.Axis<am4charts.AxisRenderer>,
    showLine: boolean,
    markType: "circle" | "arrow-down" | "arrow-up"
  ): am4charts.XYSeries {
    const series = chart.series.push(
      showLine ? new am4charts.LineSeries() : new am4charts.XYSeries()
    );
    series.dataFields.dateX = "date"; // 日付毎
    series.dataFields.valueY = value; // 値
    series.name = name; // 凡例の名前
    series.tooltipText = "{" + value + "}" + suffix; // ツールチップ
    series.stroke = am4core.color(color); // 折れ線の色
    series.strokeWidth = 3; // 折れ線の幅
    series.fill = am4core.color(color); // 凡例の色
    series.yAxis = yAxis; // y軸とのマッピング

    // データポイントの設定
    const bullet = series.bullets.push(new am4charts.Bullet());
    if (markType === "circle") {
      const circle = bullet.createChild(am4core.Circle);
      circle.width = 10;
      circle.height = 10;
      circle.horizontalCenter = "middle";
      circle.verticalCenter = "middle";
    } else {
      const arrow = bullet.createChild(am4core.Triangle);
      if (markType === "arrow-up") {
        arrow.direction = "top";
      } else {
        arrow.direction = "bottom";
      }
      arrow.width = 10;
      arrow.height = 10;
      arrow.horizontalCenter = "middle";
      arrow.verticalCenter = "middle";
    }

    return series;
  }

  // チャートデータ作成
  private makeChartData() {
    const data = this.progress.records.map(elem => {
      const mapData: {
        date: unknown;
        temp?: unknown;
        pulse?: unknown;
        breath?: unknown;
        blood_extends_right?: unknown;
        blood_contraction_right?: unknown;
        blood_extends_left?: unknown;
        blood_contraction_left?: unknown;
      } = {
        date: elem.date
      };
      if (elem.chart.temp && elem.chart.temp != "0") {
        mapData.temp = elem.chart.temp;
      }
      if (elem.chart.pulse) {
        mapData.pulse = elem.chart.pulse;
      }
      if (elem.chart.breath) {
        mapData.breath = elem.chart.breath;
      }
      if (elem.chart.blood_extends_right) {
        mapData.blood_extends_right = elem.chart.blood_extends_right;
      }
      if (elem.chart.blood_contraction_right) {
        mapData.blood_contraction_right = elem.chart.blood_contraction_right;
      }
      if (elem.chart.blood_extends_left) {
        mapData.blood_extends_left = elem.chart.blood_extends_left;
      }
      if (elem.chart.blood_contraction_left) {
        mapData.blood_contraction_left = elem.chart.blood_contraction_left;
      }
      return mapData;
    });
    return data;
  }
  private setTooltipAlways(
    chart: am4charts.XYSeries,
    value: string,
    suffix: string
  ) {
    const bullet = chart.bullets.push(new am4charts.Bullet());
    bullet.showTooltipOn = "always";
    bullet.tooltipText = "{" + value + "}" + suffix;
  }

  private chartStyle(): string {
    if (!this.isPrint) {
      return "height: 700px;";
    } else if (!this.showScroll) {
      return "height: 270px;";
    } else {
      return "height: 330px;";
    }
  }

  // 観察項目情報取得
  getProgress(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/progress/get",
      {
        patient_id: this.patientId,
        chart_type: this.ChartType,
        target_date: this.targetDate
      },
      res => {
        this.progress = res.data.progress;
        this.tableDataProgress = res.data.table_data_progress;
        this.chart.data = [];
        this.chart.dispose();
        this.$nextTick(() => {
          this.makeChart();
        });
      }
    );
  }

  public get ChartType(): number {
    const idx = this.tabs.findIndex(tab => tab.value == this.chartType);
    if (idx === -1 || !this.tabs[idx].other_string) {
      return 1;
    }
    return Number(this.tabs[idx].other_string) || 1;
  }
}
