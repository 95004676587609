import { MutationTree } from "vuex";
import {
  FirestoreState,
  SET_OBSERVER,
  SET_DOCUMENT_ID,
  Observer,
  setSnapShot,
  SET_DOCUMENT_DATA
} from "./types";
import { firebase, db } from "@/firebase";

export const mutations: MutationTree<FirestoreState> = {
  [SET_DOCUMENT_ID](state, documentId: string) {
    if (state.documentId === documentId) {
      //ドキュメントIDが同じであれば、何もしない
      return;
    }
    //ドキュメントIDが切り替わったタイミングでオブザーバーを停止する
    if (state.unsubscribe != undefined) {
      state.unsubscribe();
    }
    state.firebaseData = db.collection(state.collection).doc(documentId);
    state.documentId = documentId;
    //既にオブザーバーが登録されていれば、変更されたドキュメントで再度リッスンする
    if (state.observers.length == 0) {
      setSnapShot(state);
    }
  },
  [SET_OBSERVER](state, observer: Observer) {
    let isSet = false;
    //同一名のオブザーバーは上書き
    for (let o of state.observers) {
      if (o.observer_name == observer.observer_name) {
        o = observer;
        isSet = true;
      }
    }
    if (!isSet) {
      state.observers.push(observer);
    }
    setSnapShot(state);
  },
  [SET_DOCUMENT_DATA](state, data?: firebase.firestore.DocumentData) {
    state.data = data;
  }
};
