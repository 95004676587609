//引き当て用担当者
export interface ApplyPersonnel {
  id: number; //機関ID
  personnel_id: number; //担当者ID
  office_code: string; //事業所コード
  relation_institution_name: string; //関連機関名
  relation_institution_tel_no: string; //関連機関電話番号
  relation_institution_fax_no: string; //関連機関FAX番号
  name: string; //担当者名
  section_name: string; //担当科
  status: number; //ステータス
  is_deleted: number; //担当者が削除済みか
}
export const DefaultApplyPersonnel = (): ApplyPersonnel => ({
  id: 0,
  personnel_id: 0,
  office_code: "",
  relation_institution_name: "",
  relation_institution_tel_no: "",
  relation_institution_fax_no: "",
  name: "",
  section_name: "",
  status: 0,
  is_deleted: 0
});

//引き当て用医師
export interface ApplyDoctor {
  id: number; //医療機関ID
  doctor_id: number; //医師ID
  medical_institution_code: string; //医療機関コード
  medical_institution_name: string; //医療機関名
  medical_institution_tel_no: string; //医療機関電話番号
  medical_institution_fax_no: string; //医療機関FAX番号
  name: string; //医師名
  section_name: string; //担当科
  status: number; //ステータス
  is_deleted: number; //医師が削除済みか
}
export const DefaultApplyDoctor = (): ApplyDoctor => ({
  id: 0,
  doctor_id: 0,
  medical_institution_code: "",
  medical_institution_name: "",
  medical_institution_tel_no: "",
  medical_institution_fax_no: "",
  name: "",
  section_name: "",
  status: 0,
  is_deleted: 0
});

//引き当て用医師
export interface ApplySameBuilding {
  id: number; //ID
  name: string; //施設名
  display_name: string; //表示名称
}
export const DefaultApplySameBuilding = (): ApplySameBuilding => ({
  id: 0,
  name: "",
  display_name: ""
});

//引き当て用職員
export interface ApplyStaff {
  id: number; //ID
  ewell_user_id: number; //eWeLL利用者ID
  ibow_nickname: string; //職員名
  job: string; //職種
}
export const DefaultApplyStaff = (): ApplyStaff => ({
  id: 0,
  ewell_user_id: 0,
  ibow_nickname: "",
  job: ""
});

//引き当て用事業所
export interface ApplyOffice {
  id: number; //ID
  name: string; //事業所名
  medical_is_seishin_kihon_ryouyouhi: number; //'医療_精神科訪問看護基本療養費\n0:なし\n1:あり',
}
export const DefaultApplyOffice = (): ApplyOffice => ({
  id: 0,
  name: "",
  medical_is_seishin_kihon_ryouyouhi: 0
});

//引き当て用ADL評価項目
export interface ApplyAdlEvaluation {
  id: number; //ADL評価項目ID
  name: string; //ADL評価項目名
  select_id: number; //ADL評価項目選択肢ID
  select_name: string; //ADL評価項目選択肢名
}
export const DefaultApplyAdlEvaluation = (): ApplyAdlEvaluation => ({
  id: 0,
  name: "",
  select_id: 0,
  select_name: ""
});

//引き当て用看護内容
export interface ApplyCareContent {
  large_id: number; //看護内容大項目ID
  large_name: string; //看護内容大項目名
  medium_id: number; //看護内容中項目ID
  medium_name: string; //看護内容中項目名
}
export const DefaultApplyCareContent = (): ApplyCareContent => ({
  large_id: 0,
  large_name: "",
  medium_id: 0,
  medium_name: ""
});

//引き当て用観察項目
export interface ApplyObservation {
  id: number; //観察項目ID
  category_name: string; //観察項目カテゴリ名
  name: string; //観察項目名
  select_id: number; //観察項目選択肢ID
  select_name: string; //観察項目選択肢名
}
export const DefaultApplyObservation = (): ApplyObservation => ({
  id: 0,
  category_name: "",
  name: "",
  select_id: 0,
  select_name: ""
});

//引き当て用利用者
export interface ApplyPatient {
  id: number; //利用者ID
  last_name: string; //利用者姓
  first_name: string; //利用者名
  last_name_kana: string; //利用者姓カナ
  first_name_kana: string; //利用者名カナ
  address: string; //住所
  age: number; //年齢
  birthday: string; //生年月日
}
export const DefaultApplyPatient = (): ApplyPatient => ({
  id: 0,
  last_name: "",
  first_name: "",
  last_name_kana: "",
  first_name_kana: "",
  address: "",
  age: 0,
  birthday: ""
});
