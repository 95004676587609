



























































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { ChoiceAddOther, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import EditMixin from "@/mixins/editMixin";
import RulesMixin from "#/mixins/rulesMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateInput from "#/components/DateInput.vue";
import {
  MedicalInsurance,
  DefaultMedicalInsurance,
  MedicalInsuranceHighExpense,
  DefaultMedicalInsuranceHighExpense
} from "#/model/patient";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";

const Ocr = namespace(ocr.name);

interface NoticeItems {
  id: number;
  start_date: string;
  end_date: string;
  code: string;
  abbreviation: string;
  detail: string;
}

interface HighExpenseTerms {
  text: string;
  value: number;
  divs: ChoiceAddOther[];
}

@Component({
  components: {
    PatientHeader,
    DateInput
  }
})
export default class InsuranceInfoHighCost extends Mixins(
  AxiosMixin,
  EditMixin,
  RulesMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  /** 高額療養費区分「その他」 */
  private readonly HIGH_EXPENSE_DIV_OTHER = 9;

  private insHighCostId = 0;
  private patientId = 0;
  private mode = "";
  private isLoaded = false;
  private dialog = false;

  private high_cost: MedicalInsuranceHighExpense = DefaultMedicalInsuranceHighExpense(); //高額療養費登録
  private high_expense_terms: HighExpenseTerms[] = []; //高額療養費設定
  private noticeItems: NoticeItems[] = []; //項目一覧
  private noticeDisp = ""; //項目一覧横表示用
  private noticeItemselects: NoticeItems[] = []; //項目一覧表示分け用
  private insurance: MedicalInsurance = DefaultMedicalInsurance(); //医療保険

  //高額療養費設定div表示作成
  private get high_expense_divs(): ChoiceAddOther[] {
    for (const term of this.high_expense_terms) {
      if (term.value === this.high_cost.high_expense_term_id) {
        return term.divs;
      }
    }
    return [];
  }

  //医療保険_特記事項コードセット
  private setSpecialNoteCode(code: string) {
    this.high_cost.special_note_code = code;
    //令和4年10月1日以降に有効な後期高齢を登録した場合、
    //「本人区分：高齢一」を選択した場合、給付割合（8割9割）高額療養費設定（一般：18000円）選択時の区分は以下になる。
    // 高齢一（8割）→ 区分（41:区カ）
    // 高齢一（9割）→ 区分（42:区キ）
    if (this.high_cost.end_date >= "2022-10-01" && this.insurance.id !== 0) {
      if (
        this.high_cost.high_expense_term_id === 4 && // 高額療養費設定(2018年8月1日以降)：70歳以上
        this.high_cost.high_expense_div_id === 2 && // 選択肢：一般 18,000
        this.insurance.insurance_type === 4 && // 保険種別：後期
        this.insurance.insured_div === 4 // 本人区分：高齢一
      ) {
        switch (this.insurance.payment_rate) {
          case 80:
            this.high_cost.special_note_code = "41";
            break;
          case 90:
            this.high_cost.special_note_code = "42";
            break;
        }
      }
    }
    this.createNotice();
  }

  //特記欄表示作成
  private createNotice() {
    if (this.high_cost.special_note_code === "") {
      this.noticeDisp = "";
      return;
    }
    for (const noticeItem of this.NoticeItems) {
      if (this.high_cost.special_note_code == noticeItem.code) {
        this.noticeDisp = noticeItem.code + " : " + noticeItem.abbreviation;
        break;
      }
    }
  }

  //項目一覧
  private headers: DataTableHeader[] = [
    {
      text: "コード",
      value: "code",
      width: "80px",
      align: "center",
      class: "pa-1"
    },
    {
      text: "略称",
      value: "abbreviation",
      width: "64px",
      align: "center",
      class: "pa-1"
    },
    {
      text: "内容",
      value: "detail",
      width: "300px",
      align: "center",
      class: "pa-1"
    }
  ];

  @Watch("high_cost.start_date")
  private watchHighCostStartDate() {
    this.fetchInsurance();
  }

  created() {
    this.insHighCostId = Number(this.$route.params.inshighcostid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchInsurerHighCost();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=HighCost`
      },
      {
        text: "高額療養費設定",
        disabled: true,
        to: ""
      }
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    //高額療養費設定・特記欄選択どちらも未選択の場合アラート
    if (
      this.high_cost.high_expense_div_id == 0 &&
      this.high_cost.special_note_code == ""
    ) {
      await this.$openAlert(
        "高額療養費設定、特記欄選択のいずれかを入力してください"
      );
      return;
    }

    if (this.mode) {
      this.high_cost.id = 0;
    }
    this.high_cost.patient_id = this.patientId;
    if (this.high_cost.high_expense_div_id !== this.HIGH_EXPENSE_DIV_OTHER) {
      // その他金額は「その他」を選んだ場合のみ
      this.high_cost.amount = 0;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/highcost/save",
      {
        high_cost: this.high_cost
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.applyOcr();
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private update() {
    this.high_cost.id = this.insHighCostId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.high_cost.id = this.insHighCostId;
      this.high_cost.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/highcost/delete",
        {
          high_cost: this.high_cost
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  //高額療養費情報取得
  private fetchInsurerHighCost(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/highcost/get",
      {
        patient_id: this.patientId,
        ins_high_cost_id: this.insHighCostId
      },
      res => {
        this.high_expense_terms = res.data.high_expense_terms
          .filter((hTerm: HighExpenseTerms) => hTerm.value >= 3)
          .map((hTerm: HighExpenseTerms) => {
            if (hTerm.value === 4) {
              hTerm.text = "70歳以上の方";
            }
            return hTerm;
          });
        this.noticeItems = res.data.special_notes;
        //高額療養費情報
        if (this.insHighCostId != 0) {
          const highCost: MedicalInsuranceHighExpense = res.data.high_cost;
          if (this.mode) {
            highCost.start_date = "";
            highCost.end_date = "";
          }
          this.high_cost = highCost;
          this.createNotice();
        }

        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 一般
    if (this.ocrState.targetKind === TargetKind.mediInsHighExpense) {
      this.high_cost.start_date = this.ocrState.mediInsHighExpense.startDate;
      this.high_cost.end_date = this.ocrState.mediInsHighExpense.endDate;
    }
  }

  //医療保険情報取得
  private fetchInsurance() {
    const start_date = this.high_cost.start_date;
    if (start_date === "") {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/patient/insurance/med/oneday/get",
      {
        patient_id: this.patientId,
        target_date: start_date
      },
      res => {
        const insurance = res.data.medical_insurance;
        if (insurance == null) {
          // 空の場合はdefaut値をセットする
          this.insurance = DefaultMedicalInsurance();
        } else {
          this.insurance = insurance;
        }
      }
    );
  }

  //-----------------------------------------------------
  // ダイアログ
  //-----------------------------------------------------
  //ダイアログを開く
  private open() {
    this.dialog = true;
  }

  //ダイアログで行選択
  private clickRow(row: NoticeItems) {
    this.noticeDisp = "";
    if (row.abbreviation) {
      this.noticeDisp = row.code + " : " + row.abbreviation;
    }
    this.high_cost.special_note_code = row.code;
    this.dialog = false;
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("high_cost", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  //高額療養費設定div：特記欄切り替え
  @Watch("high_cost.high_expense_div_id")
  private handleHighExpenseDiv() {
    if (!this.isLoaded) {
      return;
    }
    for (const highExpenseDiv of this.high_expense_divs) {
      if (highExpenseDiv.value == this.high_cost.high_expense_div_id) {
        this.setSpecialNoteCode(highExpenseDiv.other_string);
        break;
      }
    }
  }

  //-----------------------------------------------------
  // 項目一覧表示切り替え
  //-----------------------------------------------------
  //項目一覧表示切り替え：高額療養費設定の選択で変更
  private get NoticeItems(): NoticeItems[] {
    //2018/07/31以前
    if (
      this.high_cost.high_expense_term_id == 1 ||
      this.high_cost.high_expense_term_id == 2
    ) {
      const target = new Date("2018-07-31".replace(/-/g, "/"));
      return this.createnoticeItems(target);
    } else {
      //2018/08/01以降
      const target = new Date("2018-08-01".replace(/-/g, "/"));
      return this.createnoticeItems(target);
    }
  }
  //項目一覧内容作成
  private createnoticeItems(target: Date): NoticeItems[] {
    this.noticeItemselects = [];
    this.noticeItemselects.push(this.noticeItems[0]);
    for (const noticeItem of this.noticeItems) {
      const start = new Date(noticeItem.start_date.replace(/-/g, "/"));
      const end = new Date(noticeItem.end_date.replace(/-/g, "/"));
      if (start <= target && target <= end) {
        this.noticeItemselects.push(noticeItem);
      }
    }
    return this.noticeItemselects;
  }
}
