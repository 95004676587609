





































































import {
  Component,
  Mixins,
  Prop,
  Emit,
  Watch,
  Ref
} from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientInfo from "@/components/patient/PatientInfo.vue";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import PatientMixin from "@/mixins/patientMixin";
import { Link } from "#/types";
import SideBar from "@/components/patient/record2/SideBar.vue";
import ExpantionActions from "./ExpantionActions.vue";

@Component({
  components: {
    PatientInfo,
    SideBar,
    ExpantionActions
  }
})
export default class PatientExpantionAreas extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  PatientMixin
) {
  /** Prop */

  /** ヘッダー(未使用) */
  @Prop() header!: HTMLElement;

  /** ページ内リンク設定 */
  @Prop({ default: () => [] }) links!: Link[];

  /** サイドバー非表示フラグ */
  @Prop({ default: false }) isHideSideBar!: boolean;

  /** Emit */

  @Emit("update:links")
  updateLinks(newValue: Link[]) {
    return newValue;
  }

  @Ref("sidebar") private readonly sidebar!: SideBar;

  @Watch("links")
  onChangeLinks() {
    // linksが変更した場合は、サイドバーを更新する
    this.sidebar?.updateLinks(this.getSelectLinks());
  }

  // リンク
  private getSelectLinks(): Link[] {
    const selectLinks = [];
    for (let index = 0; index < this.links.length; index++) {
      const link = this.links[index];
      if (this.keyOpenTargeIndexes.findIndex(v => v === link.key) === -1) {
        this.keyOpenTargeIndexes.push(link.key);
      }

      const targetIndex = this.keyOpenTargeIndexes.findIndex(
        v => v === link.key
      );
      const num = this.openedPanels.findIndex(v => {
        return v === targetIndex;
      });
      if (num > -1) {
        link.is_select = true;
      } else {
        link.is_select = false;
      }
      selectLinks.push(link);
    }
    return selectLinks;
  }

  /** キーに対応するインデックス(Vuetifyのバグでlink配列のindexとvalueの指定indexが異なるため、ここでkeyに対応する指定indexを保持しておく) */
  private keyOpenTargeIndexes: string[] = [];

  /** パネル展開状態 */
  private openedPanels: number[] = [];

  mounted() {
    // アコーディオンのキー一覧を用意する
    this.links.forEach(link => this.keyOpenTargeIndexes.push(link.key));
    const selectedLinks = this.links.filter(link => link.is_select);
    if (selectedLinks.length > 0) {
      selectedLinks.map(link => {
        // "開いている"設定の項目をクリックイベントで開き、サイドバーの状態も更新
        const panel = document.getElementById(link.key);
        const header = panel?.querySelector(".ibow2-expansion-item-header");
        (header as HTMLElement)?.click();
      });
    } else {
      this.$nextTick(() => {
        // サイドバーの状態を更新
        this.sidebar?.updateLinks(this.getSelectLinks());
      });
    }
  }

  // パネル展開
  private open(_: number, key: string) {
    let scrollDelay = 1;
    const keyIdx = this.keyOpenTargeIndexes.findIndex(v => v === key);
    if (
      this.openedPanels.findIndex(panelIndex => panelIndex === keyIdx) == -1
    ) {
      scrollDelay = 400;

      // Keyからボタンコンポーネントを検索し、クリックイベントを発火させて
      // 選択項目を開く
      const panel = document.getElementById(key);
      if (panel) {
        const header = panel.getElementsByClassName(
          "ibow2-expansion-item-header"
        );
        if (header && header.length > 0) {
          (header[0] as HTMLElement).click(); // パネルクリックイベント発火
        }
      }
    }
    setTimeout(() => {
      const ele = document.getElementById(key);
      const topPos = ele?.offsetTop ?? 0;
      window.scrollTo(0, topPos);
    }, scrollDelay);
  }

  // パネルクリックイベント
  // 現在開いているか、削除されているかを判定して開いているパネル一覧に追加or削除する
  private onClickPanel(e: MouseEvent) {
    if (!e.target) {
      return;
    }
    const target = e.target as HTMLElement;
    const parent = target.closest(".ibow2-expansion-item");
    if (!parent) {
      return;
    }
    const key = parent.id;
    if (key == "") {
      return;
    }
    const keyIdx = this.keyOpenTargeIndexes.findIndex(v => v === key);
    const idx = this.openedPanels.findIndex(
      panelIndex => panelIndex === keyIdx
    );
    if (idx === -1) {
      this.openedPanels.push(keyIdx);
    } else {
      this.openedPanels.splice(idx, 1);
    }
    // 選択状態が変わっているので、サイドバーを更新する。
    this.sidebar.updateLinks(this.getSelectLinks());
  }

  //バリデーションチェック
  public validate(): boolean {
    let ok = true;
    const newLink: Link[] = [];
    for (const link of this.links) {
      const refs = this.$refs[link.key] as unknown[];
      const ref = refs[0] as VForm;
      if (!ref.validate()) {
        ok = false;
        link.is_false = true;
        newLink.push(link);
      } else {
        link.is_false = false;
        newLink.push(link);
      }
    }
    //その他エリアチェック
    if (this.$scopedSlots["others"]) {
      const ref = (this.$refs.others as unknown) as VForm;
      if (!ref.validate()) {
        ok = false;
      }
    }
    //先頭エリアチェック
    if (this.$scopedSlots["tops"]) {
      const ref = (this.$refs.tops as unknown) as VForm;
      if (!ref.validate()) {
        ok = false;
      }
    }
    this.updateLinks(newLink);
    return ok;
  }

  //バリデーションリセット（書類保存時に使用）
  public resetValidation() {
    for (const link of this.links) {
      const refs = this.$refs[link.key] as unknown[];
      const ref = refs[0] as VForm;
      ref.resetValidation();
    }

    //その他エリア
    const refOthers = (this.$refs.others as unknown) as VForm | undefined;
    refOthers?.resetValidation();

    //先頭エリア
    const refTops = (this.$refs.tops as unknown) as VForm | undefined;
    refTops?.resetValidation();

    return;
  }
}
