


































































import { Component, Mixins } from "vue-property-decorator";
import Title from "@/components/home/title.vue";
import ContentArea from "@/components/home/contentArea.vue";
import SecondArea from "@/components/home/secondArea.vue";
import BulletinBoard from "@/components/home/bulletinBoard.vue";
import Hour24 from "@/components/home/hour24.vue";
import Unsubmit from "@/components/home/unsubmit.vue";
import {
  MenuButton,
  ButtonItems,
  DefaultAttend,
  DefaultContentsDisplay,
  InitCount,
  MENU_BUTTON
} from "@/components/home/types";
import { DefaultSearchCondition } from "#/model/fulltime";
import { COLLECTION_HOME_ITEM_LIST } from "@/const/envFireStore";
import FirestoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { BoardItem } from "#/model/fulltime";

@Component({
  components: {
    Title,
    ContentArea,
    SecondArea,
    BulletinBoard,
    Hour24,
    Unsubmit
  }
})
export default class Home extends Mixins(
  FirestoreMixin,
  UtilMixin,
  AxiosMixin
) {
  /** ボタンの情報 */
  private buttonArea: {
    responseButtonItems: MenuButton[];
    buttonItems: ButtonItems;
    cloneButtonItems: ButtonItems;
    buttonItemsLoading: boolean;
    documentLoading: boolean;
  } = {
    /** レスポンスボタン情報 */
    responseButtonItems: [],
    /** メニューボタン */
    buttonItems: {
      primaryButtonItems: [],
      secondaryButtonItems: [],
      selectFontSize: 0,
      contentsDisplay: DefaultContentsDisplay()
    },
    /** メニューボタン（失敗した場合に元に戻す） */
    cloneButtonItems: {
      primaryButtonItems: [],
      secondaryButtonItems: [],
      selectFontSize: 0,
      contentsDisplay: DefaultContentsDisplay()
    },
    /** メニューボタンエリアのローディング */
    buttonItemsLoading: true,
    /** FirestoreDocumentのローディング */
    documentLoading: true
  };

  /** 打刻と件数 */
  private attendAndCount = {
    attend: DefaultAttend(),
    count: InitCount(),
    attendLoading: true,
    countLoading: true
  };

  /** 本日の予定 */
  private todaySchedule = {
    items: [],
    count: 0,
    loading: true
  };

  /** 管理者お知らせ */
  private adminNotice = {
    items: [],
    loading: true
  };

  /** 未読の24時間体制 */
  private unread24Hour: {
    items: BoardItem[];
    countUrgency: number;
    loading: boolean;
  } = {
    items: [],
    countUrgency: 0,
    loading: true
  };

  /** 未提出書類 */
  private unsubmitReport = {
    items: [],
    loading: true
  };

  created() {
    this.fetchButtonItems();
    this.fetchDocumentData();
    this.fetchCount();
    this.fetchTodaySchedule();
    this.fetchAdminNotice();
    this.fetchHour24Data();
    this.fetchUnsubmitReport();
  }

  /** メニューボタン取得 */
  fetchButtonItems() {
    this.postJsonCheck(
      window.base_url + "/api/home/get-button-items",
      {},
      res => {
        this.buttonArea.responseButtonItems = res.data?.button_items ?? [];
        this.attendAndCount.attend = res.data?.attend ?? DefaultAttend();
        this.buttonArea.buttonItemsLoading = false;
        this.sortButtons();
        this.fetchAttend();
      },
      () => {
        this.buttonArea.buttonItemsLoading = false;
      }
    );
  }

  /** メニューボタンの並びの情報をFirestoreから取得 */
  async fetchDocumentData() {
    this.collection = COLLECTION_HOME_ITEM_LIST;
    this.documentId = String(this.loginUser.id);
    const documentButtonItem = (await this.documentGet()) as ButtonItems;
    if (!documentButtonItem) {
      if (documentButtonItem === false) {
        // firestore取得失敗
        this.$openAlert(
          "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
        );
        return;
      }
    } else {
      this.buttonArea.buttonItems = documentButtonItem;
      if (!documentButtonItem.selectFontSize) {
        this.buttonArea.buttonItems.selectFontSize = 0;
      }
      if (!documentButtonItem.contentsDisplay) {
        this.buttonArea.buttonItems.contentsDisplay = DefaultContentsDisplay();
      }
    }
    this.buttonArea.documentLoading = false;
    this.sortButtons();
  }

  /** 勤怠情報の取得処理 */
  fetchAttend() {
    this.postJsonCheck(
      window.base_url + "/api/home/get-kintai-info",
      { attend: this.attendAndCount.attend },
      res => {
        this.attendAndCount.attendLoading = false;
        this.attendAndCount.attend = res.data.attend;
      },
      () => {
        this.attendAndCount.attendLoading = false;
      }
    );
  }

  /** 未読/未提出件数の取得処理 */
  fetchCount() {
    this.postJsonCheck(
      window.base_url + "/api/home/get-count",
      {},
      res => {
        this.attendAndCount.countLoading = false;
        this.attendAndCount.count = res.data;
        this.sortButtons();
      },
      () => {
        this.attendAndCount.countLoading = false;
      }
    );
  }

  /** 本日の予定取得 */
  fetchTodaySchedule() {
    this.postJsonCheck(
      window.base_url + "/api/home/get-today-schedule",
      {},
      res => {
        this.todaySchedule.loading = false;
        this.todaySchedule.items = res.data?.schedules ?? [];
        this.todaySchedule.count = res.data?.today_schedules_count;
      },
      () => {
        this.todaySchedule.loading = false;
      }
    );
  }

  /** お知らせの取得処理 */
  fetchAdminNotice() {
    this.postJsonCheck(
      window.base_url + "/api/home/get-admin-notice",
      {},
      res => {
        this.adminNotice.loading = false;
        this.adminNotice.items = res.data?.notices ?? [];
      },
      () => {
        this.adminNotice.loading = false;
      }
    );
  }

  /** 24時間体制の取得処理 */
  fetchHour24Data() {
    const cond = DefaultSearchCondition();
    // Home 画面では全部取得する
    cond.patient_status = {
      enabled: 1,
      break: 1,
      end: 1
    };
    this.postJsonCheck(
      window.base_url + "/api/home/fulltime-search",
      {
        search_cond: {
          ...cond,
          part: {
            patient_id: cond.patient_id,
            keyword: cond.keyword,
            office_ids: cond.office_ids,
            group_ids: cond.group_ids.filter(Boolean),
            group_operator: cond.group_operator
          }
        },
        limit: 5,
        is_reading: 0, // 0:未読 1:既読 ホーム画面では未読のみ取得する
        is_only_login_office: true
      },
      res => {
        this.unread24Hour.loading = false;
        this.unread24Hour.items = res.data.all_items ?? [];
        this.unread24Hour.countUrgency =
          this.unread24Hour.items.filter(hour24 => {
            return hour24.support_div === 1; // 緊急対応
          }).length ?? 0;
      },
      () => {
        this.unread24Hour.loading = false;
      }
    );
  }

  /** 未提出書類取得 */
  fetchUnsubmitReport() {
    this.postJsonCheck(
      window.base_url + "/api/home/get-not-submit-reports",
      {},
      res => {
        this.unsubmitReport.loading = false;
        this.unsubmitReport.items = res.data?.not_submits ?? [];
      },
      () => {
        this.unsubmitReport.loading = false;
      }
    );
  }

  /** メニューボタンをFirestoreの情報に基づいて並び替える */
  private sortButtons() {
    if (this.buttonArea.buttonItemsLoading || this.buttonArea.documentLoading) {
      return;
    }

    const primaryButtonItems = [];
    const secondaryButtonItems = [];
    for (const button of this.buttonArea.responseButtonItems) {
      if (
        button.is_divider ||
        button.url === "/" ||
        button.url === "/dashboard"
      ) {
        continue; // 区切り線とホームボタンはアイテムとして扱わない
      }

      //利用者情報の未提出件数設定
      if (button.id == MENU_BUTTON.PATIENT) {
        button.info_count = String(
          this.attendAndCount.count.count_patient_info
        );
      }
      //看護記録の未提出件数設定
      if (button.id == MENU_BUTTON.RECORD) {
        button.info_count = String(
          this.attendAndCount.count.count_visit_record
        );
      }
      //24時間体制の未読件数設定
      if (button.id == MENU_BUTTON.HOUR24) {
        button.info_count = String(this.attendAndCount.count.count_fulltime);
      }
      //掲示板の未読件数設定
      if (button.id == MENU_BUTTON.BBS) {
        button.info_count = String(this.attendAndCount.count.count_admin);
        button.another_info_count = this.attendAndCount.count.count_topic;
      }

      //Firestore上、メインボタンかどうか
      let index = this.buttonArea.buttonItems.primaryButtonItems.findIndex(
        x => x.id === button.id
      );
      if (index !== -1) {
        button.index = index;
        primaryButtonItems.push(button);
        continue;
      }

      //Firestore上、サブボタンかどうか
      index = this.buttonArea.buttonItems.secondaryButtonItems.findIndex(
        x => x.id === button.id
      );
      if (index !== -1) {
        button.index = index;
        secondaryButtonItems.push(button);
        continue;
      }

      //Firestore上、どちらでもない場合はボタンの情報で判定
      button.index = 99;
      if (button.is_second === 0) {
        primaryButtonItems.push(button);
      } else {
        secondaryButtonItems.push(button);
      }
    }

    // インデックス順に並び替え
    primaryButtonItems.sort((a, b) => {
      return a.index - b.index;
    });
    secondaryButtonItems.sort((a, b) => {
      return a.index - b.index;
    });

    this.buttonArea.buttonItems.primaryButtonItems = primaryButtonItems;
    this.buttonArea.buttonItems.secondaryButtonItems = secondaryButtonItems;
  }

  /** メインメニューの編集を開始した時の処理 */
  onStartEdit() {
    this.buttonArea.cloneButtonItems = this.deepCopy(
      this.buttonArea.buttonItems
    );
  }

  /** メインメニューの編集が完了した時の処理 */
  async onEndEdit() {
    const res = await this.documentSave(this.buttonArea.buttonItems);
    // 失敗した場合は前の状態に戻す
    if (!res) {
      this.buttonArea.buttonItems = this.buttonArea.cloneButtonItems;
    }
    window.font_size = this.buttonArea.buttonItems.selectFontSize;
  }
}
